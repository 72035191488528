import React, {Component} from 'react';
import App from './App';
import {Provider} from 'react-redux';
import LeopardUserLogin from './pages/LeopardUserLogin';


class LeopardAuthenticator extends Component {


    render() {
        let authState = localStorage.getItem("authState");
        if (authState === null) authState = "signIn";

        if (authState !== "signedIn") {
            return (
                <React.Fragment>
                    <LeopardUserLogin authState={"signIn"}/>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <Provider store={this.props.store}>
                    <div id={"RootWindowContainer"}><App authState={authState}/></div>
                </Provider>
            </React.Fragment>
        );
    }
}

export default LeopardAuthenticator;
import React, {Component} from 'react';
import Box, {Item} from 'devextreme-react/box';
import {NumberBox, SelectBox, TextArea, TextBox} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LRH from "../helpers/LeopardReactHelper";
import LDPH from "../helpers/LeopardDropdownHelper";
import $ from 'jquery';
import {Button as TextBoxButton} from "devextreme-react/text-box";
import List from "devextreme-react/list";

class LeopardEditorFieldsPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            definitionParameters: null
        };
        this.uiObjectInstances = [];
        this.dropdownEditorText = "";
    }

    componentDidMount() {
        if (!LDH.IsObjectNull(this.props.definitionParameters)) {
            let data = LDH.DeepClone(this.props.definitionParameters);
            this.setState({
                definitionParameters: data
            });
        } else {
            this.setState({
                definitionParameters: []
            });
        }
    };

    componentWillUnmount = () => {
        let uiObjectInstances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(uiObjectInstances);
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null ||
            data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.input] = data.e.instance;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstances;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    updateValueForParameters = (data) => {
        let that = this;
        let clonedParameters = LDH.DeepClone(this.state.definitionParameters);
        for (let i = 0; i < clonedParameters.length; i++) {
            let currentColumn = clonedParameters[i];
            if (currentColumn.id === data.id) {
                let pName = data.propertyName;
                if (!LDH.IsObjectNull(data.inputValue)) {
                    currentColumn[pName] = data.inputValue;
                } else {
                    currentColumn[pName] = data.e.value;
                }
            }
        }

        this.setState({definitionParameters: clonedParameters}, function () {
            that.props.definitionParametersOnChanged(that.state);

            if (data.propertyName === "fieldType") {
                that.showOrHideFieldPanelsByType(data.e.value, data.index, that);
            }
        });
    };

    showOrHideFieldPanelsByType = (fieldType, index, thisComp) => {
        setTimeout(function () {
            if (fieldType === "numberbox") {
                $(".form-editor-minimum-value_" + index + "_container").show();
                $(".form-editor-maximum-value_" + index + "_container").show();
                $(".form-editor-allowdecimal_" + index + "_container").show();
                $(".form-editor-dateformat_" + thisComp.props.dataViewId + "_" + index + "_container").hide();
                $(".form-editor-dropdowneditor_" + index + "_container").hide();
            } else if (fieldType === "date" || fieldType === "datetime") {
                $(".form-editor-minimum-value_" + index + "_container").hide();
                $(".form-editor-maximum-value_" + index + "_container").hide();
                $(".form-editor-allowdecimal_" + index + "_container").hide();
                $(".form-editor-dateformat_" + thisComp.props.dataViewId + "_" + index + "_container").show();
                $(".form-editor-dropdowneditor_" + index + "_container").hide();
            } else if (fieldType === "dropdown") {
                $(".form-editor-minimum-value_" + index + "_container").hide();
                $(".form-editor-maximum-value_" + index + "_container").hide();
                $(".form-editor-allowdecimal_" + index + "_container").hide();
                $(".form-editor-dateformat_" + thisComp.props.dataViewId + "_" + index + "_container").hide();
                $(".form-editor-dropdowneditor_" + index + "_container").show();
            } else {
                $(".form-editor-minimum-value_" + index + "_container").hide();
                $(".form-editor-maximum-value_" + index + "_container").hide();
                $(".form-editor-allowdecimal_" + index + "_container").hide();
                $(".form-editor-dateformat_" + thisComp.props.dataViewId + "_" + index + "_container").hide();
                $(".form-editor-dropdowneditor_" + index + "_container").hide();
            }
        }, 100);
    };

    parameterDeleteOnClick = (data) => {
        let that = this;
        let clonedParameters = LDH.DeepClone(this.state.definitionParameters);
        let filteredParameters = [];
        for (let i = 0; i < clonedParameters.length; i++) {
            if (clonedParameters[i].id !== data.id) {
                filteredParameters.push(clonedParameters[i]);
            }
        }
        let keys = Object.keys(this.uiObjectInstances);
        for (let j = 0; j < keys.length; j++) {
            if (keys[j].startsWith("parameters_" + this.props.dataViewId +
                "_uiControl_" + data.index)) {
                delete this.uiObjectInstances[keys[j]];
            }
        }
        this.setState({definitionParameters: filteredParameters}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    parametersAddOnClick = () => {
        let that = this;
        this.setState({
            definitionParameters: [
                ...this.state.definitionParameters, {
                    id: LDH.GenerateGuid(),
                    displayOrder: 0
                }
            ]
        }, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    addUIObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.ref) ||
            LDH.IsObjectNull(data.ref.ref.instance)) {
            return;
        }
        this.uiObjectInstances[data.uiObjectName] = data.ref.ref.instance;
    };

    autoApplyParameterFilterValueOnChange = (e) => {
        let that = this;
        this.setState({autoApplyParameterFilter: e.value}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    }

    listViewTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    dropdownEditorOnValueChanged = (e) => {
        this.dropdownEditorText = e.value;
    };

    render() {
        let that = this;
        if (LDH.IsObjectNull(this.state.definitionParameters)) return null;

        let result = this.state.definitionParameters.map(function (item, i) {
            that.showOrHideFieldPanelsByType(item.fieldType, i, that);

            return (
                <Item key={i} baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>{LDH.IsValueEmpty(item.parameterName)
                                ? "Parameter " + (i + 1)
                                : item.parameterName}
                            </span>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button remove fas fa-minus"
                               onClick={(e) => that.parameterDeleteOnClick({
                                   e, id: item.id, index: i
                               })}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorFieldName_" + i + "_help"}
                                    title={"Field name"}
                                    text={"Specify a parameter name for the field."}/>
                                <span>Parameter name:</span>
                            </div>
                            <div>
                                <TextBox value={item.fieldName} maxLength={255}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_formEditorFieldName"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: item.id,
                                             propertyName: "fieldName", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorColumnName_" + i + "_help"}
                                    title={"Field name"}
                                    text={"Specify a name for the field."}/>
                                <span>Field name:</span>
                            </div>
                            <div>
                                <TextBox value={item.columnName} maxLength={255}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_formEditorColumnName"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: item.id,
                                             propertyName: "columnName", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorFieldDisplayName_" + i + "_help"}
                                    title={"Display name"}
                                    text={"Specify a display name for the field."}/>
                                <span>Display name:</span>
                            </div>
                            <div>
                                <TextBox value={item.fieldDisplayName} maxLength={255}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_formEditorFieldDisplayName"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: item.id,
                                             propertyName: "fieldDisplayName", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorFieldParentFieldName_" + i + "_help"}
                                    title={"Parent field name"}
                                    text={"Specify a parent field name for the field."}/>
                                <span>Parent field name:</span>
                            </div>
                            <div>
                                <TextBox value={item.parentFieldName} maxLength={255}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_formEditorParentFieldName"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: item.id,
                                             propertyName: "parentFieldName", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorIsReadOnly_" + i + "_help"}
                                    title={"Read only"}
                                    text={"Specify whether this field is ready only."}/>
                                <span>Read only:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i + "_formEditorIsReadOnly"
                                           })}
                                           value={item.isReadOnly}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "isReadOnly", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorFocusOnFieldIndexId_" + i + "_help"}
                                    title={"Focus on field custom index ID"}
                                    text={"Specify a custom index ID for focusing on a input field."}/>
                                <span>Focus on field custom index ID:</span>
                            </div>
                            <div>
                                <TextBox value={item.focusOnFieldIndexId}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_formEditorFocusOnFieldIndexId"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: item.id,
                                             propertyName: "focusOnFieldIndexId", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorSubmitOnEnterKey_" + i + "_help"}
                                    title={"Submit on enter key"}
                                    text={"Specify whether allow to submit data on enter key."}/>
                                <span>Submit on enter key:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i + "_formEditorSubmitOnEnterKey"
                                           })}
                                           value={item.submitOnEnterKey}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "submitOnEnterKey", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorSubmitOnTabKey_" + i + "_help"}
                                    title={"Submit on tab key"}
                                    text={"Specify whether allow to submit data on tab key."}/>
                                <span>Submit on tab key:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i + "_formEditorSubmitOnTabKey"
                                           })}
                                           value={item.submitOnTabKey}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "submitOnTabKey", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorSubmitButtonJqueryClass_" + i + "_help"}
                                    title={"Submit button jquery class"}
                                    text={"Specify a jquery class for the submit button."}/>
                                <span>Submit button jquery class:</span>
                            </div>
                            <div>
                                <TextBox value={item.submitButtonJqueryClass}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_formEditorSubmitButtonJqueryClass"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: item.id,
                                             propertyName: "submitButtonJqueryClass", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorAllowFocusOnTab_" + i + "_help"}
                                    title={"Allow focus on tab"}
                                    text={"Specify whether allow to focus on tab."}/>
                                <span>Allow focus on tab:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i + "_formEditorAllowFocusOnTab"
                                           })}
                                           value={item.allowFocusOnTab}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "allowFocusOnTab", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorNextTabJqueryId_" + i + "_help"}
                                    title={"Next tab jquery ID"}
                                    text={"Specify a jquery ID for the next tab."}/>
                                <span>Next tab jquery ID:</span>
                            </div>
                            <div>
                                <TextBox value={item.nextTabJqueryId}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_formEditorNextTabJqueryId"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: item.id,
                                             propertyName: "nextTabJqueryId", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorEnableManualLockEnabled_" + i + "_help"}
                                    title={"Manual lock"}
                                    text={"Specify whether to enable manual lock."}/>
                                <span>Manual lock:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i + "_formEditorManualLockEnabled"
                                           })}
                                           value={item.manualLockEnabled}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "manualLockEnabled", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorFieldType_" + i + "_help"}
                                    title={"Field type"}
                                    text={"Specify a type of field."}/>
                                <span>Field type:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionParameterType}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_formEditorFieldType"
                                           })}
                                           value={item.fieldType}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "fieldType", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div
                            className={"leopard-settings-panel-contentgroup form-editor-dropdowneditor_" + i + "_container"}>
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorDropdownEditor_" + i + "_help"}
                                    title={"Dropdown editor"}
                                    text={"Specify a list of dropdown values for the field."}/>
                                <span>Dropdown editor:</span>
                            </div>
                            <div>
                                <div className="leopard-settings-panel-content-label">
                                    <TextBox maxLength={100} showClearButton={true}
                                             ref={(e) => that.setInputFieldInstance({
                                                 e,
                                                 input: "parameters_" + that.props.dataViewId +
                                                     "_uiControl_" + i +
                                                     "_formEditorDropdownEditor"
                                             })}
                                             onValueChanged={(e) => that.dropdownEditorOnValueChanged(e)}>
                                        <TextBoxButton name={"formEditorDropdownEditor_MiniButton"}
                                                       location={'after'}
                                                       options={{
                                                           icon: "fas fa-plus",
                                                           type: 'default',
                                                           onClick: () => {
                                                               if (LDH.IsValueEmpty(that.dropdownEditorText)) {
                                                                   return;
                                                               }
                                                               let dropdownValuesTemp = item.dropdownValues;
                                                               if (LDH.IsObjectNull(dropdownValuesTemp)) {
                                                                   dropdownValuesTemp = [];
                                                               }
                                                               dropdownValuesTemp.push({
                                                                   id: that.dropdownEditorText,
                                                                   name: that.dropdownEditorText
                                                               });

                                                               let id = "parameters_" + that.props.dataViewId +
                                                                   "_uiControl_" + i +
                                                                   "_formEditorDropdownEditorList";

                                                               var inputId = "parameters_" + that.props.dataViewId +
                                                                   "_uiControl_" + i +
                                                                   "_formEditorDropdownEditor";

                                                               that.uiObjectInstances[id].option("dataSource", dropdownValuesTemp);
                                                               that.uiObjectInstances[inputId].option("value", "");
                                                               that.uiObjectInstances[inputId].focus();

                                                               that.updateValueForParameters({
                                                                   inputValue: dropdownValuesTemp, id: item.id,
                                                                   propertyName: "dropdownValues", item: item, index: i
                                                               });
                                                           }
                                                       }}>
                                        </TextBoxButton>
                                    </TextBox>
                                </div>
                                <div className={"leopard-listcontainer"} style={{height: "200px"}}>
                                    <List allowItemDeleting={true} activeStateEnabled={false}
                                          noDataText={"Click Add to insert a value"}
                                          hoverStateEnabled={false} focusStateEnabled={false}
                                          dataSource={item.dropdownValues}
                                          ref={(e) => that.setInputFieldInstance({
                                              e,
                                              input: "parameters_" + that.props.dataViewId +
                                                  "_uiControl_" + i +
                                                  "_formEditorDropdownEditorList"
                                          })}
                                          itemRender={that.listViewTemplate}>
                                    </List>
                                </div>
                            </div>
                        </div>
                        <div
                            className={"leopard-settings-panel-contentgroup form-editor-minimum-value_" + i + "_container"}>
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorMinimumValue_" + i + "_help"}
                                    title={"Minimum value"}
                                    text={"Specify a minimum value for the field."}/>
                                <span>Minimum value:</span>
                            </div>
                            <div>
                                <NumberBox value={item.minValue}
                                           min={-99999999} max={99999999} showSpinButtons={true}
                                           format={"#0"} showClearButton={true}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_formEditorMinimumValue"
                                           })}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "minValue", item: item, index: i
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                        <div
                            className={"leopard-settings-panel-contentgroup form-editor-maximum-value_" + i + "_container"}>
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorMaximumValue_" + i + "_help"}
                                    title={"Maximum value"}
                                    text={"Specify a maximum value for the field."}/>
                                <span>Maximum value:</span>
                            </div>
                            <div>
                                <NumberBox value={item.maxValue}
                                           min={-99999999} max={99999999} showSpinButtons={true}
                                           format={"#0"} showClearButton={true}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_formEditorMaximumValue"
                                           })}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "maxValue", item: item, index: i
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                        <div
                            className={"leopard-settings-panel-contentgroup form-editor-allowdecimal_" + i + "_container"}>
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorAllowDecimal_" + i + "_help"}
                                    title={"Allow decimal point"}
                                    text={"Specify whether allows user to type a decimal point in the field."}/>
                                <span>Allow decimal point:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_formEditorAllowDecimal"
                                           })}
                                           value={item.allowDecimal}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "allowDecimal", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div
                            className={"leopard-settings-panel-contentgroup form-editor_" + i + "_container"}>
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorDefaultAutoFocus_" + i + "_help"}
                                    title={"Default auto-focus"}
                                    text={"Specify whether this field is focused by default."}/>
                                <span>Default auto-focus:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_formEditorDefaultAutoFocus"
                                           })}
                                           value={item.defaultAutoFocus}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "defaultAutoFocus", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div
                            className={"leopard-settings-panel-contentgroup form-editor_" + i + "_container"}>
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorClearDataOnFormSubmit_" + i + "_help"}
                                    title={"Clear data on form submit"}
                                    text={"Specify whether the data is cleared when form has submitted."}/>
                                <span>Clear data on form submit:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_formEditorClearDataOnFormSubmit"
                                           })}
                                           value={item.defaultAutoFocus}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "clearDataOnFormSubmit", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div
                            className={"leopard-settings-panel-contentgroup form-editor-dateformat_" + that.props.dataViewId + "_" + i + "_container"}>
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorDateFormat_" + i + "_help"}
                                    title={"Date format"}
                                    text={"Specify a date format for the field."}/>
                                <span>Date format:</span>
                            </div>
                            <div>
                                <TextBox value={item.dateFormat} maxLength={255}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_formEditorDateFormat"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: item.id,
                                             propertyName: "dateFormat", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorDefaultValue_" + i + "_help"}
                                    title={"Default value"}
                                    text={"Specify a default value for the field."}/>
                                <span>Default value:</span>
                            </div>
                            <div>
                                <TextBox value={item.defaultValue}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_formEditorDefaultValue"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: item.id,
                                             propertyName: "defaultValue", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorValidationRules_" + i + "_help"}
                                    title={"Validation rules"}
                                    text={"Specify validation rules for the field."}/>
                                <span>Validation rules:</span>
                            </div>
                            <div>
                                <TextArea value={item.validationRules} height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: item.id,
                                              propertyName: "validationRules", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorValueOnChangedLogic_" + i + "_help"}
                                    title={"Value on changed logic"}
                                    text={"Specify a custom logic when value has been changed."}/>
                                <span>Value on changed logic:</span>
                            </div>
                            <div>
                                <TextArea value={item.valueOnChangedLogic} height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: item.id,
                                              propertyName: "valueOnChangedLogic", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorFormDataOnChangedLogic_" + i + "_help"}
                                    title={"Form data on changed logic"}
                                    text={"Specify a custom logic when form data has been changed."}/>
                                <span>Form data on changed logic:</span>
                            </div>
                            <div>
                                <TextArea value={item.formDataOnChangedLogic} height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: item.id,
                                              propertyName: "formDataOnChangedLogic", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorDefaultValueShapingLogic_" + i + "_help"}
                                    title={"Default value shaping logic"}
                                    text={"Specify a custom logic for the shaping default value."}/>
                                <span>Default value shaping logic:</span>
                            </div>
                            <div>
                                <TextArea value={item.defaultValueShapingLogic} height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: item.id,
                                              propertyName: "defaultValueShapingLogic", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorTriggerDefaultValueLogicOnDataReceived_" + i + "_help"}
                                    title={"Run default value logic on data received"}
                                    text={"Specify whether to run default value logic on data received."}/>
                                <span>Run default value logic on data received:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_formEditorTriggerDefaultValueLogicOnDataReceived"
                                           })}
                                           value={item.triggerDefaultValueLogicOnDataReceived}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e,
                                               id: item.id,
                                               propertyName: "triggerDefaultValueLogicOnDataReceived",
                                               item: item,
                                               index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"formEditorDisplayOrder_" + i + "_help"}
                                    title={"Display order"}
                                    text={"Specify a display order for the field."}/>
                                <span>Display order:</span>
                            </div>
                            <div>
                                <NumberBox value={item.displayOrder}
                                           min={0} max={99} showSpinButtons={true} format={"#0"}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: item.id,
                                               propertyName: "displayOrder", item: item, index: i
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                    </div>
                </Item>
            );
        });

        return (
            <Box direction={'row'} width={'100%'}>
                <Item baseSize={60}>
                    <div className="leopard-settings-panel-cell">
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button fas fa-plus"
                               onClick={(e) => this.parametersAddOnClick(e)}></i>
                        </div>
                    </div>
                </Item>
                {result}
            </Box>
        )
    }
}

export default LeopardEditorFieldsPanel;

import React from 'react';
import LDH from '../helpers/LeopardDataHelper';
import websocket from 'websocket';
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LeopardCognitoConfig from "../foundation/LeopardCognitoConfig";
import aws4 from "aws4";

class LeopardWebsocketHelper extends React.Component {
    static IsWebsocketEnabled = () => {
        //return false;
        let isLocal = window.location.hostname === "localhost";
        if (isLocal) LeopardStaticUIConfig.ControlCentreWebsocketEnabled = true;

        let configValue = LeopardStaticUIConfig.ControlCentreWebsocketEnabled;
        return LDH.IsValueTrue(configValue);
    }

    static InitializeWebsocketClient = () => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) return null;
        let endpoint = LDH.WebsocketEndpointAdapter();

        let accessInfo = {
            accessKeyId: window.userCredential.accessKeyId,
            secretAccessKey: window.userCredential.secretAccessKey,
            sessionToken: window.userCredential.sessionToken
        };

        let signedReq = aws4.sign({
            host: endpoint.replace("wss://", ""),
            path: `/?X-Amz-Security-Token=${encodeURIComponent(accessInfo.sessionToken)}`,
            region: LeopardCognitoConfig.AmplifyAuthAndAPIConfig.Auth.region,
            service: "execute-api",
            signQuery: true
        }, accessInfo);

        return new websocket.w3cwebsocket(`${endpoint}${signedReq.path}`);
    };

    static InitializeWebsocketClientEvents = (client, callback) => {
        client.onerror = function () {
            console.log("websocket error occurred, connection closed.");
            client.close();
        };
        client.onopen = function () {
            console.log("websocket connection opened.");
            if (client.readyState === 1) client.send({});
        };
        client.onmessage = function (e) {
            let connectionId = "";
            if (!LDH.IsObjectNull(e) && !LDH.IsObjectNull(e.data) &&
                !LDH.IsValueEmpty(e.data) &&
                e.data.indexOf("Internal server error") > -1 &&
                e.data.indexOf("connectionId") > -1) {
                let value = JSON.parse(e.data);
                connectionId = value.connectionId;
            }
            if (!LDH.IsObjectNull(e) && !LDH.IsObjectNull(e.data) &&
                !LDH.IsValueEmpty(e.data)) {
                callback(JSON.parse(e.data), connectionId);
            }
        };
    };

    static ForceReconnectOnInitialError = (connectionString, requestConnectionString, responseData) => {
        if (typeof responseData.message === "string" && responseData.message.indexOf("Internal server error") > -1) {
            if (typeof window[connectionString] === "undefined" || window[connectionString] === null) return false;
            let connectionId = responseData.connectionId;

            if (typeof connectionId !== "undefined" && connectionId !== null && connectionId !== "") {
                let requestData = window[requestConnectionString];
                if (typeof requestData === "undefined" || LDH.IsObjectNull(requestData)) return false;

                requestData.id = LDH.GenerateGuid();
                requestData.responseroute = "{\"type\":\"direct\",\"connectionid\":\"" + connectionId + "\"}";
                LeopardWebsocketHelper.SendMessage(JSON.stringify(requestData), window[connectionString]);
            }
            return false;
        }
        return true;
    };

    static OpenConnectionForControlCentre = (app, callback) => {
        var client = LeopardWebsocketHelper.InitializeWebsocketClient();
        if (LDH.IsObjectNull(client)) return;

        client.onclose = function () {
            console.log("websocket connection closed, trying to reconnect.");
            LeopardWebsocketHelper.OpenConnectionForControlCentre(app, callback);
        };
        LeopardWebsocketHelper.InitializeWebsocketClientEvents(client, callback);
        window["websocket_connection_cc_global"] = client;
    };

    static OpenConnectionForDataView = (app, persistentId, callback) => {
        var client = LeopardWebsocketHelper.InitializeWebsocketClient();
        if (LDH.IsObjectNull(client)) return;

        client.onclose = function () {
            console.log("websocket connection closed, trying to reconnect.");
            LeopardWebsocketHelper.OpenConnectionForDataView(app, persistentId, callback);
        };
        LeopardWebsocketHelper.InitializeWebsocketClientEvents(client, callback);
        window["websocket_connection_" + persistentId] = client;
    };

    static SendMessage = (message, websocketInstance) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) return;
        let websocket;
        if (typeof window.websocketInstance !== "undefined" && window.websocketInstance !== null &&
            websocketInstance === null) {
            websocket = window.websocketInstance;
        }
        if (typeof websocketInstance !== "undefined" && websocketInstance !== null) {
            websocket = websocketInstance;
        }
        if (LDH.IsObjectNull(websocket)) return;
        if (websocket.readyState === 1) websocket.send(message);
    };

    static CloseConnection = (websocketInstance, persistentId) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) return;
        let websocket;

        if (typeof window.websocketInstance !== "undefined" && window.websocketInstance !== null &&
            websocketInstance === null) {
            websocket = window.websocketInstance;
        }
        if (typeof websocketInstance !== "undefined" && websocketInstance !== null) {
            websocket = websocketInstance;
        }
        if (LDH.IsObjectNull(websocket)) return;
        websocket.close();

        if (typeof window["websocket_connection_request_" + persistentId] !== "undefined") {
            window["websocket_connection_request_" + persistentId] = null;
            delete window["websocket_connection_request_" + persistentId];
        }

        if (typeof window["websocket_connection_" + persistentId] !== "undefined") {
            window["websocket_connection_" + persistentId] = null;
            delete window["websocket_connection_" + persistentId];
        }
    };

    static GetMessageTemplate = (type, connectionId, data, eventKey, responseRouteType) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        if (LDH.IsObjectNull(window.browserTabId) || LDH.IsValueEmpty(window.browserTabId)) {
            window.browserTabId = LDH.GenerateGuid();
        }
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let responseRoute = "";
        if (responseRouteType === "connectionId") {
            responseRoute = "{\"type\":\"direct\",\"connectionid\":\"" + connectionId + "\"}";
        }
        if (responseRouteType === "userId") {
            responseRoute = "{\"type\":\"map\",\"mapping\": [ { \"type\": \"user-sub\", " +
                "\"subs\": [ \"" + userId + "\"] } ] }";
        }

        return {
            "specversion": "1.0",
            "type": type,
            "source": "urn:" + window.browserTabId + ":tbd:websocket",
            "id": eventKey,
            "time": new Date().toISOString(),
            "responseroute": responseRoute,
            "data": data
        }
    }

    static GetMessageTemplateForSubscription = (type, connectionId, subscribeTo, eventKey, responseRouteType) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        if (LDH.IsObjectNull(window.browserTabId) || LDH.IsValueEmpty(window.browserTabId)) {
            window.browserTabId = LDH.GenerateGuid();
        }
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let responseRoute = "";
        if (responseRouteType === "connectionId") {
            responseRoute = "{\"type\":\"direct\",\"connectionid\":\"" + connectionId + "\"}";
        }
        if (responseRouteType === "userId") {
            responseRoute = "{\"type\":\"map\",\"mapping\": [ { \"type\": \"user-sub\", " +
                "\"subs\": [ \"" + userId + "\"] } ] }";
        }

        return {
            "specversion": "1.0",
            "type": type,
            "source": "urn:" + window.browserTabId + ":tbd:websocket",
            "id": eventKey,
            "time": new Date().toISOString(),
            "responseroute": responseRoute,
            "data": {
                "subscriptions": [
                    {
                        "type": "event",
                        "value": subscribeTo
                    }
                ]
            }
        }
    }
}

export default LeopardWebsocketHelper;
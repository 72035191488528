import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, NumberBox, SelectBox, TextArea} from 'devextreme-react';
import {Button as TextBoxButton, TextBox} from 'devextreme-react/text-box';
import Box, {Item} from 'devextreme-react/box';
import TabPanel from 'devextreme-react/tab-panel';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import {RequiredRule, Validator} from 'devextreme-react/validator';
import $ from "jquery";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import LeopardDataSourcePanel from "../datashaping/LeopardDataSourcePanel";

class LeopardDocumentEditorDesigner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: 0,
            documentDefinition: {}
        };
        this.uiObjectInstance = [];
        this.documentDefinitionDataSources = [];
    }

    componentDidMount = () => {
        let definition = this.props.definition;
        this.documentDefinitionDataSources = definition.dataSources;
        if (LDH.IsObjectNull(definition.dataSources)) {
            this.documentDefinitionDataSources = [];
        }
        this.setState({documentDefinition: definition});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null ||
            data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;
    };

    updateDocumentDefinition = (data) => {
        let inputValue = data.e.value;
        let documentDef = this.state.documentDefinition;
        let clonedDocumentDef = LDH.DeepClone(documentDef);
        clonedDocumentDef[data.prop] = inputValue;
        this.setState({documentDefinition: clonedDocumentDef});
    };

    getDefaultValue = (name, defaultValue) => {
        if (this.state.documentDefinition[name] === undefined) {
            return defaultValue;
        }
        return this.state.documentDefinition[name];
    };

    showOrHideSettings = (settingsName) => {
        let show = false;

        if (settingsName === "dataPreviewerVisibility") show = true;
        if (settingsName === "dataPreviewerDefaultWidth") show = true;
        if (settingsName === "dataPreviewerVisibilityByLocation") show = true;
        if (settingsName === "documentEditorDataFormat") show = true;
        if (settingsName === "documentEditorAPIGatewayAuthUrl") show = true;
        if (settingsName === "documentEditorAPIGatewayGetUrl") show = true;
        if (settingsName === "documentEditorAPIGatewayQueryLogic") show = true;
        if (settingsName === "documentEditorAPIGatewayPostUrl") show = true;
        if (settingsName === "documentEditorDataReceivingLogic") show = true;
        if (settingsName === "documentEditorDataSendingLogic") show = true;
        if (settingsName === "documentEditorDataSchema") show = true;
        if (settingsName === "documentEditorUISchema") show = true;
        if (settingsName === "documentEditorAllowStandaloneAccess") show = true;
        if (settingsName === "documentEditorAPIGatewayAuthUrl") show = true;
        if (settingsName === "documentEditorAPIGatewayAuthQueryLogic") show = true;
        if (settingsName === "documentEditorAuthResultLogic") show = true;
        if (settingsName === "documentEditorDirectory") show = true;
        if (settingsName === "documentEditorFileName") show = true;

        return show;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    saveDataButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;
        let definition = that.state.documentDefinition;
        let dataViewId = that.props.dataViewId;
        $(".loading-progress-configureeditor").css("visibility", "visible");

        that.uiObjectInstance["designer_save_button"].option("disabled", true);
        that.uiObjectInstance["designer_close_button"].option("disabled", true);

        setTimeout(function () {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            definition.dataSources = that.documentDefinitionDataSources;

            LeopardAjaxHelper.UpdateDataViewDefinitionByType(userId, organizationId,
                dataViewId, definition, function () {
                    LRH.ShowToast("Your configuration has been successfully saved.", "success", 5000);
                    $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
                    $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");

                    that.uiObjectInstance["designer_save_button"].option("disabled", false);
                    that.uiObjectInstance["designer_close_button"].option("disabled", false);
                }, function (error, sessionTimeout) {
                    if (error === "version-out-of-date") {
                        LRH.ShowStaticToast("Configuration outdated",
                            "Your current configuration settings cannot be saved due to a newer version found on the server, please refresh your " +
                            "browser. ", "error", true);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                    }
                    $(".loading-progress-configureeditor").css("visibility", "hidden");
                    that.uiObjectInstance["designer_save_button"].option("disabled", false);
                    that.uiObjectInstance["designer_close_button"].option("disabled", false);
                }, "document-editor");
        }, 100);
    };

    onSelectionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            this.setState({
                selectedIndex: args.value
            });
        }
    };

    cancelButtonOnClick = (e) => {
        this.props.documentEditorDesignerButtonOnClick(e);
    };

    initializeTabItemTitle = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    initializeSettingsTextBoxField = (data) => {
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                            </div>
                            <div>
                                <TextBox defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                         ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                         readOnly={(!LDH.IsObjectNull(data.readonly) && data.readonly)}
                                         onValueChanged={(e) => this.updateDocumentDefinition({
                                             e, tabInfo: data, prop: data.settingsName,
                                         })} showClearButton={true} placeholder={data.placeholder}>
                                    <Validator>
                                        <RequiredRule validationCallback={(e) => this.customValidationRuleCallback({
                                            e, input: data.settingsName, rules: [{rule: "safeinput"}]
                                        })} type="custom">
                                        </RequiredRule>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsSelectBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <SelectBox dataSource={data.dataSource} displayExpr={'name'} valueExpr={'id'}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateDocumentDefinition({
                                               e, tabInfo: data, prop: data.settingsName,
                                               supportPreview
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsNumberBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <NumberBox min={data.minValue} max={data.maxValue} showSpinButtons={true}
                                           showClearButton={true}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateDocumentDefinition({
                                               e, tabInfo: data, prop: data.settingsName,
                                               supportPreview
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsTextAreaWithButtonField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        let height = LDH.IsObjectNull(data.height) ? "143px" : data.height;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <TextArea defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                          ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                          id={"Textbox_" + data.settingsName} height={height}
                                          onValueChanged={(e) => this.updateDocumentDefinition({
                                              e, tabInfo: data, prop: data.settingsName,
                                              supportPreview
                                          })} placeholder={data.placeholder}>
                                    {
                                        data.validator === false ? "" :
                                            <Validator>
                                                <RequiredRule
                                                    validationCallback={(e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: data.settingsName,
                                                        rules: [{rule: "safeinput"}]
                                                    })} type="custom"/>
                                            </Validator>
                                    }
                                    {
                                        data.hasMiniButton === false ? "" :
                                            <TextBoxButton location={'after'} options={{
                                                icon: data.buttonIcon, type: 'default',
                                                onClick: () => {
                                                    data.onClick();
                                                }
                                            }} name={data.settingsName + "_MiniButton"}>
                                            </TextBoxButton>
                                    }
                                </TextArea>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    documentDefinitionDataSourcesOnChanged = (data) => {
        this.documentDefinitionDataSources = data;
    }

    tabItemContent = (data) => {
        if (data.tabId === 0) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto", maxHeight: "432px"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">JSON Editor</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataPreviewerVisibility",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionEnableForAdminUsers[1].id,
                                            displayName: "Visibility by user roles:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionEnableForAdminUsers
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataPreviewerVisibilityByLocation",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionVisibilityByLocation[2].id,
                                            displayName: "Visibility by location:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionVisibilityByLocation
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "dataPreviewerDefaultWidth",
                                            defaultValue: 300,
                                            displayName: "Default width:",
                                            minValue: 0,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "documentEditorDataFormat",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionDocumentEditorDataFormat[0].id,
                                            displayName: "Document format:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionDocumentEditorDataFormat
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "documentEditorAllowStandaloneAccess",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Allow standalone access:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Schemas</div>
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "documentEditorDataSchema",
                                            defaultValue: undefined,
                                            supportPreview: false,
                                            validator: false,
                                            displayName: "Data schema:",
                                            hasMiniButton: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "documentEditorUISchema",
                                            defaultValue: undefined,
                                            supportPreview: false,
                                            validator: false,
                                            displayName: "UI schema",
                                            hasMiniButton: false
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 1) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto", maxHeight: "432px"}} className={"leopard-dataview-configbox"}>
                        <LeopardDataSourcePanel
                            documentDefinitionDataSources={this.documentDefinitionDataSources}
                            documentDefinitionDataSourcesOnChanged={(e) => this.documentDefinitionDataSourcesOnChanged(e)}
                        />
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.saveDataButtonOnClick}>
                    <div style={{width: "100%", overflowX: "auto"}}>
                        <div className={"leopard-editgridview-title"}>Configure Editor</div>
                        <div className={"leopard-gray-panel-container"} style={{marginTop: "10px"}}>
                            <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionDocumentEditorTabPanel}
                                      onOptionChanged={this.onSelectionChanged}
                                      loop={true} itemTitleRender={this.initializeTabItemTitle}
                                      itemComponent={this.tabItemContent}
                                      animationEnabled={true} swipeEnabled={false}
                            ></TabPanel>
                        </div>
                    </div>
                    <div style={{padding: "10px 0px 10px 10px", minWidth: "900px", clear: "both"}}>
                        <span style={{padding: "0 10px 0 0"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Save"}
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "designer_save_button"})}
                                    useSubmitBehavior={true}>
                            </Button>
                        </span>
                        <span style={{padding: "0 0 0 5px"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Close"}
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "designer_close_button"})}
                                    onClick={(e) => this.cancelButtonOnClick({e})}>
                            </Button>
                        </span>
                        <span className={"loading-progress-configureeditor"}>
                            <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                        </span>
                    </div>
                </form>
                <br/>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardDocumentEditorDesigner);

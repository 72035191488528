import 'babel-polyfill/dist/polyfill';
import 'whatwg-fetch/fetch';
import 'devextreme/data/odata/store';
import './resources/css/fontawesome/css/all.min.css';
import 'devextreme/dist/css/dx.common.css';
import './resources/css/devextreme/dx.diagram-leopard-theme.css';
import './resources/css/devextreme/dx.generic.leopard-theme.css';

import React from 'react';
import {createRoot} from 'react-dom/client';
import {createStore} from 'redux';
import LeopardReducer from './foundation/LeopardReducer';
import {Amplify} from 'aws-amplify';
import LeopardAuthenticator from './LeopardAuthenticator';
import LeopardCognitoConfig from "./foundation/LeopardCognitoConfig";
const store = createStore(LeopardReducer);

Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

const root = createRoot(document.getElementById('root'));
root.render(
    <div style={{overflow: "hidden"}}>
        <LeopardAuthenticator store={store}/>
    </div>);


import React, {Component} from 'react';
import {connect} from 'react-redux';
import LeopardAnalyticsDashboardEngine from "../foundation/LeopardAnalyticsDashboardEngine";

class LeopardAnalyticsDashboardConfiguration extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardAnalyticsDashboardEngine
                    analyticsDefinition={this.props.analyticsDefinition}
                    dataViewId={this.props.dataViewId} isDataView={this.props.isDataView}
                />
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardAnalyticsDashboardConfiguration);

import React, {Component} from 'react';
import $ from 'jquery';
import {Button, FileUploader, TextBox} from 'devextreme-react';
import {SelectBox} from 'devextreme-react/select-box';
import LRH from "../helpers/LeopardReactHelper";
import {Popover} from "devextreme-react/popover";
import LDH from "../helpers/LeopardDataHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";

class LeopardDocumentUploader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVisible: false,
            directory: this.props.fileUploaderList[0].id,
            ownerType: "userid",
            fileName: "",
            fileContent: ""
        };
        this.uiObjectInstances = [];
        this.instanceInitialized = false;
        this.disposingAllInstances = false;
    }

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    addUIObjectInstance = (data, addToParent) => {
        this.uiObjectInstances[data.name] = data.instance;

        if (addToParent === true) {
            this.props.setComponentInstance({
                componentName: data.name,
                e: data.instance
            });
        }
    };

    popupOnHiding = () => {
        let instance = this.uiObjectInstances["documentFileSelector"];
        if (!LDH.IsObjectNull(instance)) {
            instance.instance.reset();
        }
        this.setState({popupVisible: false});
    };

    popupOnShowing = (e) => {

    };

    popupOnShown = () => {
        let that = this;
        if (that.instanceInitialized === false) {
            that.instanceInitialized = true;
            LRH.AddDisposablePopupInstances(that);
        }
    };

    directoryOnValueChanged = (e) => {
        this.setState({directory: e.value});
    };

    ownerTypeOnValueChanged = (e) => {
        this.setState({ownerType: e.value});
    };

    fileNameOnValueChanged = (e) => {
        this.setState({fileName: e.value});
    };

    cancelUploadOnClick = (e) => {
        this.uiObjectInstances["documentUploaderControl"].instance.hide();
    };

    fileUpload = (e) => {
        let that = this;
        let fileContent = this.state.fileContent;
        let fileName = this.state.fileName;

        if (!that.props.createNewFormViaPopup) {
            if (LDH.IsValueEmpty(this.state.directory) || LDH.IsValueEmpty(this.state.ownerType) ||
                LDH.IsValueEmpty(fileName) || LDH.IsValueEmpty(fileContent)) {
                LRH.ShowToast("Validation failed. The directory and file name cannot be blank.", "error", 5000);
                return;
            }
            if (LDH.IsValueEmpty(fileContent)) {
                LRH.ShowToast("Validation failed. The file content cannot be blank.", "error", 5000);
                return;
            }
            if (fileName.toLowerCase().indexOf(".json") > -1) {
                fileContent = JSON.parse(this.state.fileContent)
            }
        } else {
            if (fileName.toLowerCase().indexOf(".json") === -1) {
                fileName += ".json";
            }
            fileContent = [];
        }

        try {
            if (this.state.directory === "cc_dataview_config" &&
                (LDH.IsObjectNull(fileContent["dataViewPersistentId"]) ||
                    LDH.IsObjectNull(fileContent["documentEditorDataSchema"]) ||
                    LDH.IsObjectNull(fileContent["documentEditorUISchema"]))) {
                LRH.ShowToast("Validation failed. Please select a valid schema document.", "error", 5000);
                return;
            }
        } catch (ex) {
            LRH.ShowToast("Validation failed. Please select a valid schema document.", "error", 5000);
            return;
        }
        $(".document-upload-popup-panel-container").addClass("shown");

        LeopardAjaxHelper.InsertOrUpdateDocumentToS3(fileContent,
            fileName, this.state.directory, function () {
                setTimeout(function () {
                    let dataViewId = that.props.dataViewId;
                    let $refresh = $("#GridView_TopBar_Refresh_" + dataViewId);
                    if ($refresh.length > 0) $refresh.trigger("click");

                    that.setState({fileContent: null, fileName: null}, function () {
                        LRH.ShowToast("Your document has been successfully uploaded.", "success", 5000);
                        that.uiObjectInstances["documentUploaderControl"].instance.hide();
                        $(".document-upload-popup-panel-container").removeClass("shown");
                    });
                }, 2000);
            }, function () {
                that.setState({fileContent: null, fileName: null}, function () {
                    LRH.ShowToast("Failed to upload document.", "error", 5000);
                    $(".document-upload-popup-panel-container").removeClass("shown");
                });
            }, this.state.ownerType, that.props.documentListType,
            that.props.documentModifyType, that.props.documentCreateType);
    };

    fileUploaderValueOnChanged = (e) => {
        let that = this;
        if (!LDH.IsObjectNull(e.value) && e.value.length > 0) {
            let name = e.value[0].name.toLowerCase();
            let extension = LDH.GetFileExtensionFromString(name);
            let readAsDataUrl = false;

            if (extension === "svg") readAsDataUrl = true;
            if (extension === "gif") readAsDataUrl = true;
            if (extension === "jpg") readAsDataUrl = true;
            if (extension === "jpeg") readAsDataUrl = true;
            if (extension === "png") readAsDataUrl = true;
            if (extension === "bmp") readAsDataUrl = true;

            let reader = new FileReader();
            reader.onload = function (event) {
                let fileData = event.target.result;
                that.setState({fileContent: fileData});
            };

            if (readAsDataUrl) {
                reader.readAsDataURL(e.value[0]);
            } else {
                reader.readAsText(e.value[0]);
            }
            this.setState({fileName: name});
        }
    };

    render() {
        if (this.disposingAllInstances) return null;
        let dataViewId = this.props.dataViewId;

        return (
            <Popover target={"#GridView_TopBar_Upload_" + dataViewId} position="bottom" width={"auto"}
                     onHiding={(e) => this.popupOnHiding(e)} onShowing={(e) => this.popupOnShowing(e)}
                     ref={(e) => this.addUIObjectInstance({name: "documentUploaderControl", instance: e}, true)}
                     onShown={(e) => this.popupOnShown(e)} shading={false} animation={null}>

                <div className={"document-upload-popup-panel-container"}>
                    <span className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse"
                           style={{color: "rgb(255, 128, 0)", fontSize: "30px"}}></i>
                    </span>
                </div>

                <div className={"document-upload-popup-panel-master-container"}>
                    <div style={{marginBottom: "10px"}}>
                        <div className={"leopard-settings-panel-content-label"}>Folder:</div>
                        <div>
                            <SelectBox displayExpr={'name'} valueExpr={'id'}
                                       defaultValue={this.state.directory}
                                       dataSource={this.props.fileUploaderList}
                                       onValueChanged={(e) => this.directoryOnValueChanged(e)}>
                            </SelectBox>
                        </div>
                    </div>
                    <div style={{marginBottom: "10px"}}>
                        <div className={"leopard-settings-panel-content-label"}>Owner:</div>
                        <div>
                            <SelectBox displayExpr={'name'} valueExpr={'id'}
                                       defaultValue={this.state.ownerType}
                                       dataSource={LeopardDropdownHelper.DropdownSelectionReferenceDataOwner}
                                       onValueChanged={(e) => this.ownerTypeOnValueChanged(e)}>
                            </SelectBox>
                        </div>
                    </div>
                    {
                        this.props.createNewFormViaPopup ?
                            <div style={{marginBottom: "10px"}}>
                                <div className={"leopard-settings-panel-content-label"}>File name:</div>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <TextBox value={this.state.fileName} width={"100%"}
                                             onValueChanged={this.fileNameOnValueChanged}>
                                    </TextBox>
                                </div>
                            </div>
                            :
                            <div style={{marginBottom: "0px"}}>
                                <div className={"leopard-settings-panel-content-label"}>Select a file to upload:</div>
                                <div>
                                    <FileUploader
                                        width={220} multiple={false} uploadMode={"useButtons"}
                                        ref={(e) => this.addUIObjectInstance({
                                            name: "documentFileSelector",
                                            instance: e
                                        }, true)}
                                        onValueChanged={(e) => this.fileUploaderValueOnChanged(e)}>
                                    </FileUploader>
                                </div>
                            </div>
                    }
                    <div style={{marginBottom: "10px", display: "flex", flexDirection: "row"}}>
                        <div style={{width: "100%", marginRight: "10px"}}>
                            <Button text={"Upload"} className={"leopard-standard-button"} width={"100%"}
                                    onClick={(e) => this.fileUpload({e: e})}></Button>
                        </div>
                        <div style={{width: "100%"}}>
                            <Button text={"Cancel"} className={"leopard-standard-button"} width={"100%"}
                                    onClick={(e) => this.cancelUploadOnClick({e: e})}></Button>
                        </div>
                    </div>
                </div>
            </Popover>
        )
    }
}

export default LeopardDocumentUploader;

import React, {Component} from 'react';
import $ from 'jquery';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LeopardAPIGatewayConfig from "./LeopardAPIGatewayConfig";
import LRH from "../helpers/LeopardReactHelper";

class LeopardAnalyticsDashboardEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.analyticsDefinition = [];
        this.dataViewId = null;
        this.embedDashboardUrl = "";
    }

    componentDidMount = () => {
        let that = this;
        this.analyticsDefinition = this.props.analyticsDefinition;
        this.dataViewId = this.props.dataViewId;

        let dashboardId = this.analyticsDefinition.quicksightDashboardId;
        let postTemplate = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
        postTemplate.type = "leopardsystems.quicksight.getdashboardurl";
        postTemplate.source = LDH.GenerateGuid();

        let postData = [
            {
                "dashboardId": dashboardId,
                "namespace": "default"
            }
        ];
        LeopardAjaxHelper.SendRequestByEventSync(function (response) {
            let $iframe = $("#leopard-analytics-iframe-" + that.dataViewId);
            that.embedDashboardUrl = response.data.EmbedUrl;
            $iframe.attr("src", that.embedDashboardUrl);
            $iframe.off("load").on("load", function () {
                that.iframeOnLoad();
            })
        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to load the dashboard.", "error", 5000);
            }
        }, postTemplate, postData);
    }

    iframeOnLoad = () => {
        $("#leopard-loading-icon-" + this.props.dataViewId).hide();
        $("#leopard-analytics-iframe-container-" + this.props.dataViewId).show();
    };

    refreshOnClick = () => {
        let that = this;
        let $iframe = $("#leopard-analytics-iframe-" + that.dataViewId);
        $iframe.attr("src", "").attr("src", that.embedDashboardUrl);
        $iframe.off("load").on("load", function () {
            that.iframeOnLoad();
        })
    };

    analyticsToolbar = () => {
        let that = this;

        return (
            <React.Fragment>
                <div className={"leopard-analytics-toolbar"} style={{minHeight: "30px"}}>
                    <span id={"gridViewToobar_" + that.props.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{fontSize: "18px"}}></i>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                elementId={"Analytics_TopBar_Refresh_" + that.props.dataViewId}
                                labelText={"Refresh"} width={250} title={"Refresh"}
                                onClick={(e) => this.refreshOnClick({e: e})}
                                text={"The Refresh button allows you to refresh data and repaint the analytics dashboard."}/>
                        </span>
                </div>
            </React.Fragment>
        )
    };

    render() {
        return (
            <React.Fragment>
                {this.analyticsToolbar()}
                <span className={"leopard-loading-icon"} id={"leopard-loading-icon-" + this.props.dataViewId}
                      style={{
                          width: "100%",
                          height: "calc(100% - 30px)",
                          marginLeft: "0px",
                          overflow: "hidden",
                          display: "flex"
                      }}>
                    <div style={{margin: "auto"}}>
                        <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"
                           style={{marginTop: "0px"}}></i>
                        <span className="leopard-largeloading-progress-text">Loading data...</span>
                    </div>
                </span>
                <div id={"leopard-analytics-iframe-container-" + this.props.dataViewId}
                     style={{height: "calc(100% - 30px)", overflow: "hidden", display: "none"}}>
                    <iframe title={"iframe"} style={{height: "100%", width: "100%", border: "none"}} frameBorder={"0"}
                            id={"leopard-analytics-iframe-" + this.props.dataViewId}>
                    </iframe>
                </div>
            </React.Fragment>
        );
    }
}

export default LeopardAnalyticsDashboardEngine;
import React, {Component} from 'react';
import $ from "jquery";
import LRH from "../helpers/LeopardReactHelper";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LDH from "../helpers/LeopardDataHelper";
import {FileUploader} from "devextreme-react";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LeopardDataHelper from "../helpers/LeopardDataHelper";
import LeopardFileUploader from "../components/LeopardFileUploader";
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import LeopardHtmlFormBuilderViewer from "./LeopardHtmlFormBuilderViewer";
import LeopardHtmlFormBuilderDesigner from "./LeopardHtmlFormBuilderDesigner";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";

import {Templates} from 'formiojs';
import LeopardHtmlFormBuilderPhoto from "./LeopardHtmlFormBuilderPhoto";
import LeopardHtmlFormBuilderSignaturePad from "./LeopardHtmlFormBuilderSignaturePad";
import {Formio} from 'formiojs';
import moment from "moment/moment";

Templates.framework = 'bootstrap';
Formio.use({
    components: {
        leopardPhoto: LeopardHtmlFormBuilderPhoto,
        leopardSignaturePad: LeopardHtmlFormBuilderSignaturePad
    }
});

class LeopardHtmlFormBuilderEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isWidgetInitialized: false,
            isDesignMode: true,
            isUnmountForms: false
        };

        this.uiObjectInstances = [];
        this.formDefinition = [];
        this.dataViewId = null;
        this.disposablePopupInstances = [];
        this.initializedListeners = false;
        this.relationshipsLinkedToDataView = [];
        this.globalVariableInitialized = false;

        this.directory = "";
        this.fileName = "";

        this.fullSchemaData = {};
        this.formDataToSubmit = [];
        this.documentMetadata = null;
        this.parentData = null;
    }

    componentDidMount() {
        let that = this;
        this.formDefinition = this.props.formDefinition;
        this.dataViewId = this.props.dataViewId;

        that.loadFormFromS3(function () {
            let defaultMode = "design-mode";
            if (!LDH.IsObjectNull(that.formDefinition.defaultMode) &&
                !LDH.IsValueEmpty(that.formDefinition.defaultMode)) {
                defaultMode = that.formDefinition.defaultMode;
            }
            that.setState({
                isDesignMode: defaultMode === "design-mode",
                isWidgetInitialized: true
            }, function () {
                that.initializeDashboardDataViewListeners();
            });
        });
    }

    initializeDashboardDataViewListeners = () => {
        let that = this;
        if (this.initializedListeners) return;
        this.initializedListeners = true;

        if (!that.state.isWidgetInitialized) return;
        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
        }

        let documentRetrieveType = "leopardsystems.document.retrieve";
        if (!LDH.IsValueEmpty(that.formDefinition.documentRetrieveType)) {
            documentRetrieveType = that.formDefinition.documentRetrieveType;
        }

        if (that.props.isDataView === false) {
            $("#HtmlFormBuilder_TopBar_Save_" + that.props.dataViewId).hide();
            $("#HtmlFormBuilder_TopBar_Upload_" + that.props.dataViewId).hide();
            $("#HtmlFormBuilder_TopBar_Load_" + that.props.dataViewId).hide();
            $("#HtmlFormBuilder_TopBar_DesignMode_" + that.props.dataViewId).hide();
            $(".dataview-parameters-container-" + that.props.dataViewId).hide();
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: "html-form-builder",
                callback(data) {
                    that.parentData = data;
                    that.retrieveDocumentByParentData(data, that, documentRetrieveType);
                }
            });
        }
    }

    retrieveDocumentByParentData = (data, thisComp, documentRetrieveType) => {
        let that = thisComp;
        for (let i = 0; i < data.features.length; i++) {
            if (data.features[i] === "rowlink") {
                that.selectedParentViewData = data.dataFromSource;
                $("#htmlFormBuilderToobar_" + that.props.dataViewId).show();

                $("#HtmlFormBuilder_TopBar_Save_" + that.props.dataViewId).show();
                $("#HtmlFormBuilder_TopBar_Upload_" + that.props.dataViewId).show();
                $("#HtmlFormBuilder_TopBar_Load_" + that.props.dataViewId).show();
                $("#HtmlFormBuilder_TopBar_DesignMode_" + that.props.dataViewId).show();
                $(".dataview-parameters-container-" + that.props.dataViewId).show();

                if (LDH.IsObjectNull(data.dataFromSource)) {
                    $("#htmlFormBuilderToobar_" + that.props.dataViewId).hide();
                    $("#HtmlFormBuilder_TopBar_Save_" + that.props.dataViewId).hide();
                    $("#HtmlFormBuilder_TopBar_Upload_" + that.props.dataViewId).hide();
                    $("#HtmlFormBuilder_TopBar_Load_" + that.props.dataViewId).hide();
                    $("#HtmlFormBuilder_TopBar_DesignMode_" + that.props.dataViewId).hide();
                    $(".dataview-parameters-container-" + that.props.dataViewId).hide();
                } else {
                    that.directory = data.dataFromSource.type;
                    that.fileName = data.dataFromSource.name;

                    if (that.directory === "draft") {
                        $("#HtmlFormBuilder_TopBar_Upload_" + that.props.dataViewId).show();
                        $("#HtmlFormBuilder_TopBar_Load_" + that.props.dataViewId).show();
                        $("#HtmlFormBuilder_TopBar_DesignMode_" + that.props.dataViewId).show();
                    } else {
                        $("#HtmlFormBuilder_TopBar_Upload_" + that.props.dataViewId).hide();
                        $("#HtmlFormBuilder_TopBar_Load_" + that.props.dataViewId).hide();
                        $("#HtmlFormBuilder_TopBar_DesignMode_" + that.props.dataViewId).hide();
                    }
                    that.documentMetadata = LDH.DeepClone(data.dataFromSource);

                    LeopardAjaxHelper.RetrieveDocumentFromS3(data.dataFromSource.owner, data.dataFromSource.name,
                        data.dataFromSource.type, function (documentData) {
                            let jsonData = {};
                            try {
                                jsonData = JSON.parse(documentData);
                            } catch (ex) {
                                jsonData = {};
                            }
                            $("#htmlFormBuilderToobar_" + that.props.dataViewId).hide();

                            that.setState({isUnmountForms: true}, function () {
                                that.setState({isUnmountForms: false}, function () {
                                    if (data.dataFromSource.name.indexOf(".json") === -1) {
                                        that.fullSchemaData = [];
                                        that.uiObjectInstances["formViewer"].updateFormSchemaAndData([], []);
                                        that.uiObjectInstances["formDesigner"].updateFormSchema([]);
                                        LRH.ShowToast("This is not a valid file for the Form Builder.", "error", 5000);
                                        return;
                                    }
                                    if (!LDH.IsJsonString(documentData)) {
                                        that.fullSchemaData = [];
                                        that.uiObjectInstances["formViewer"].updateFormSchemaAndData([], []);
                                        that.uiObjectInstances["formDesigner"].updateFormSchema([]);
                                        LRH.ShowToast("This is not a valid file for the Form Builder.", "error", 5000);
                                        return;
                                    }
                                    that.fullSchemaData = jsonData;
                                    if (LDH.IsObjectNull(jsonData.data)) jsonData.data = [];

                                    let schema = jsonData.components;
                                    that.uiObjectInstances["formViewer"].updateFormSchemaAndData(schema, jsonData.data);
                                    that.uiObjectInstances["formDesigner"].updateFormSchema(schema);
                                });
                            });
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                            }
                            $("#htmlFormBuilderToobar_" + that.props.dataViewId).hide();
                        }, documentRetrieveType);
                }
            }
        }
    };

    addDisposablePopupInstances = (e) => {
        for (let i = 0; i < e.length; i++) {
            this.disposablePopupInstances.push(e[i]);
        }
    };

    setComponentInstance = (data) => {
        if (LDH.IsObjectNull(data.e) || LDH.IsObjectNull(data.e.instance)) return;
        this.uiObjectInstances[data.componentName] = data.e.instance;
    }

    componentWillUnmount = () => {
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);

        let popupInstances = this.disposablePopupInstances;
        LRH.DisposeUIInstancesFromList(popupInstances);
    };

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.name] = data.e;

        if (typeof data.properties !== "undefined") {
            instances[data.name + "_properties"] = data.properties;
        }
    };

    htmlFormBuilderLoad = () => {
        let $root = $("#HtmlFormBuilder_TopBar_FileUploader_" + this.props.dataViewId);
        $(".dx-fileuploader-button.dx-button.dx-button-normal", $root).trigger("click");
    };

    htmlFormBuilderSave = () => {
        LRH.DownloadStringToFile("Form.json", JSON.stringify(this.fullSchemaData));
    };

    htmlFormBuilderViewMode = () => {
        let that = this;
        let jsonData = this.fullSchemaData;
        let schema = jsonData.components;

        that.setState({isDesignMode: false}, function () {
            that.uiObjectInstances["formViewer"].updateFormSchema(schema);
            that.uiObjectInstances["formDesigner"].updateFormSchema(schema);
        });
    };

    htmlFormBuilderDesignMode = () => {
        let that = this;
        let jsonData = this.fullSchemaData;
        let schema = jsonData.components;

        that.setState({isDesignMode: true}, function () {
            that.uiObjectInstances["formViewer"].updateFormSchema(schema);
            that.uiObjectInstances["formDesigner"].updateFormSchema(schema);
        });
    };

    updateFullSchema = (data) => {
        this.fullSchemaData = data;
    };

    updateFormData = (data) => {
        this.formDataToSubmit = data;
    };

    loadFormFromS3 = (callback) => {
        let that = this;
        let ownerType = "orgid";
        let directory = that.formDefinition.formLoadDirectory;
        let fileName = that.formDefinition.formLoadFileName;
        $("#htmlFormBuilderToobar_" + that.props.dataViewId).show();

        if (!LDH.IsObjectNull(that.formDefinition.formLoadOwnerId) &&
            !LDH.IsValueEmpty(that.formDefinition.formLoadOwnerId)) {
            ownerType = that.formDefinition.formLoadOwnerId;
        }

        if (LDH.IsValueEmpty(directory) || LDH.IsValueEmpty(fileName)) {
            callback();
            return;
        }

        let ownerId = "";
        if (ownerType === "userid") {
            ownerId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
        } else {
            ownerId = LeopardDataHelper.GetOrganizationIdFromUserProfile(window.userProfile);
        }

        let documentRetrieveType = "leopardsystems.document.retrieve";
        if (!LDH.IsValueEmpty(that.formDefinition.documentRetrieveType)) {
            documentRetrieveType = that.formDefinition.documentRetrieveType;
        }

        LeopardAjaxHelper.RetrieveDocumentFromS3(ownerId, fileName, directory, function (documentData) {
            let jsonData = {};
            try {
                jsonData = JSON.parse(documentData);
            } catch (ex) {
                jsonData = {};
            }
            if (!LDH.IsJsonString(documentData)) {
                $("#htmlFormBuilderToobar_" + that.props.dataViewId).hide();
                callback();
                return;
            }
            that.fullSchemaData = jsonData;
            let schema = jsonData.components;

            that.uiObjectInstances["formViewer"].updateFormSchema(schema);
            that.uiObjectInstances["formDesigner"].updateFormSchema(schema);

            setTimeout(function () {
                $("#htmlFormBuilderToobar_" + that.props.dataViewId).hide();
                callback();
            }, 100);

        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve document.", "error", 5000);
            }
            $("#htmlFormBuilderToobar_" + that.props.dataViewId).hide();
            callback();
        }, documentRetrieveType);
    };

    schemaFileUploaderOnSelectedFileChanged = (data) => {
        let that = this;
        let reader = new FileReader();
        reader.onload = function (event) {
            let fileData = JSON.parse(event.target.result);
            that.fullSchemaData = fileData;
            let schema = fileData.components;

            that.uiObjectInstances["formViewer"].updateFormSchema(schema);
            that.uiObjectInstances["formDesigner"].updateFormSchema(schema);
        };
        if (data.e.value.length > 0) {
            reader.readAsText(data.e.value[0]);
        }
    };

    htmlFormBuilderUpload = (data) => {
        if ($("#LeopardFileUploader_TopBar_Upload_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        if (LDH.IsObjectNull(this.formDefinition.saveToParentLocation) ||
            LDH.IsValueEmpty(this.formDefinition.saveToParentLocation) ||
            this.props.isDataView) {
            this.uiObjectInstances["fileUploaderControl"].option("visible", true);
        } else {
            this.fileUpload();
        }
    };

    fileUpload = () => {
        let that = this;
        if (LDH.IsValueEmpty(this.directory) || LDH.IsValueEmpty(this.fileName)) {
            LRH.ShowToast("Validation failed. The directory and file name cannot be blank.", "error", 5000);
            return;
        }
        $("#htmlFormBuilderToobar_" + that.props.dataViewId).show();

        let ownerType = "orgid";
        if (!LDH.IsObjectNull(that.formDefinition.formLoadOwnerId) &&
            !LDH.IsValueEmpty(that.formDefinition.formLoadOwnerId)) {
            ownerType = that.formDefinition.formLoadOwnerId;
        }
        if (typeof that.parentData !== "undefined" && typeof that.parentData.dataFromSource !== "undefined") {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            if (that.parentData.dataFromSource.owner.toLowerCase() === userId.toLowerCase()) {
                ownerType = "userid";
            }
        }

        let documentModifyType = "leopardsystems.document.modify";
        if (!LDH.IsValueEmpty(that.formDefinition.documentModifyType)) {
            documentModifyType = that.formDefinition.documentModifyType;
        }

        let documentCreateType = "leopardsystems.document.create";
        if (!LDH.IsValueEmpty(that.formDefinition.documentCreateType)) {
            documentCreateType = that.formDefinition.documentCreateType;
        }

        let documentListType = "leopardsystems.document.list";
        if (!LDH.IsValueEmpty(that.formDefinition.documentListType)) {
            documentListType = that.formDefinition.documentListType;
        }

        let metadata = null;
        if (typeof that.formDefinition.attachParamsAsMetadata !== "undefined" &&
            !LDH.IsValueEmpty(that.formDefinition.attachParamsAsMetadata)) {
            metadata = {}
            let objKeys = Object.keys(that.uiObjectInstances);
            for (let i = 0; i < objKeys.length; i++) {
                if (objKeys[i].startsWith("dataViewParam_") && objKeys[i].endsWith("_properties")) continue;
                if (!objKeys[i].startsWith("dataViewParam_")) continue;
                let properties = that.uiObjectInstances[objKeys[i] + "_properties"];
                let metadataName = properties.metadataName;
                let inputValue = that.uiObjectInstances[objKeys[i]].instance.option("value");
                if (properties.parameterType === "datetime") {
                    if (!LDH.IsValueEmpty(inputValue)) {
                        inputValue = moment(inputValue).format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        inputValue = "";
                    }
                }
                metadata[metadataName] = inputValue;
            }
        }

        LeopardAjaxHelper.InsertOrUpdateDocumentToS3(this.fullSchemaData,
            this.fileName, this.directory, function () {
                LRH.ShowToast("Your file has been successfully uploaded.", "success", 5000);
                $("#htmlFormBuilderToobar_" + that.props.dataViewId).hide();
            }, function () {
                LRH.ShowToast("Failed to upload the file.", "error", 5000);
                $("#htmlFormBuilderToobar_" + that.props.dataViewId).hide();
            }, ownerType, documentListType, documentModifyType, documentCreateType, metadata);
    };

    formToolbar = (formDefinition) => {
        let that = this;

        if (!LDH.IsObjectNull(formDefinition.globalVariablesInit) && !LDH.IsValueEmpty(formDefinition.globalVariablesInit) &&
            !that.globalVariableInitialized) {
            let javascript = formDefinition.globalVariablesInit;
            let dataName = "data";
            let dataValue = "";
            LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, that.props.dataViewId, null);
            that.globalVariableInitialized = true;
        }

        let hideMode = false;
        if (!LDH.IsObjectNull(formDefinition.hideModeButton) && formDefinition.hideModeButton) {
            hideMode = formDefinition.hideModeButton;
        }
        let hideLoad = false;
        if (!LDH.IsObjectNull(formDefinition.hideLoadButton) && formDefinition.hideLoadButton) {
            hideLoad = formDefinition.hideLoadButton;
        }
        let hideSave = false;
        if (!LDH.IsObjectNull(formDefinition.hideSaveButton) && formDefinition.hideSaveButton) {
            hideSave = formDefinition.hideSaveButton;
        }
        let hideUpload = false;
        if (!LDH.IsObjectNull(formDefinition.hideUploadButton) && formDefinition.hideUploadButton) {
            hideUpload = formDefinition.hideUploadButton;
        }

        return (
            <React.Fragment>
                <div className={"leopard-htmlformbuilder-toolbar"} style={{minHeight: "30px"}}>
                    {
                        (!LDH.IsObjectNull(formDefinition.topMenuJustify) && formDefinition.topMenuJustify === "right") ?
                            <div style={{width: "100%"}}></div> : <div></div>
                    }
                    {
                        LRH.RenderDataViewParameters(formDefinition.parameters, that.props.dataViewId,
                            formDefinition, function () {
                            }, function (data) {
                                that.setUIInstance({e: data.e, name: data.input, properties: data.properties});
                            }, null, null)
                    }
                    {
                        (LDH.IsObjectNull(formDefinition.topMenuJustify) || formDefinition.topMenuJustify === "left") ?
                            <div style={{width: "100%"}}></div> : <div></div>
                    }
                    <span id={"htmlFormBuilderToobar_" + that.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{fontSize: "18px"}}></i>
                    </span>
                    <span style={{padding: "0 2px 0 0", display: "flex"}}>
                            <LeopardTooltipWithLink
                                controlVisible={!that.state.isDesignMode && !hideMode}
                                elementId={"HtmlFormBuilder_TopBar_DesignMode_" + that.dataViewId}
                                additionalClass={"HtmlFormBuilder_TopBar_DesignMode_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"htmlFormBuilder_topbar_designmode"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Design Mode"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.htmlFormBuilderDesignMode({e})}/>
                    </span>
                    <span style={{padding: "0 2px 0 0", display: "flex"}}>
                            <LeopardTooltipWithLink
                                controlVisible={that.state.isDesignMode && !hideMode}
                                elementId={"HtmlFormBuilder_TopBar_ViewMode_" + that.dataViewId}
                                additionalClass={"HtmlFormBuilder_TopBar_ViewMode_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"htmlFormBuilder_topbar_viewmode"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"View Mode"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.htmlFormBuilderViewMode({e})}/>
                    </span>
                    <span style={{padding: "0 2px 0 0", display: "flex"}}>
                            <LeopardTooltipWithLink
                                controlVisible={!hideLoad}
                                elementId={"HtmlFormBuilder_TopBar_Load_" + that.dataViewId}
                                additionalClass={"HtmlFormBuilder_TopBar_Load_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"htmlFormBuilder_topbar_load"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Load from File"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.htmlFormBuilderLoad({e})}/>
                    </span>
                    <span style={{padding: "0 2px 0 0", display: "flex"}}>
                            <LeopardTooltipWithLink
                                controlVisible={!hideSave}
                                elementId={"HtmlFormBuilder_TopBar_Save_" + that.dataViewId}
                                additionalClass={"HtmlFormBuilder_TopBar_Save_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"htmlFormBuilder_topbar_save"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Save to File"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.htmlFormBuilderSave({e})}/>
                    </span>
                    <span style={{padding: "0 2px 0 0", display: "flex"}}>
                            <LeopardTooltipWithLink
                                controlVisible={!hideUpload}
                                elementId={"HtmlFormBuilder_TopBar_Upload_" + that.dataViewId}
                                additionalClass={"HtmlFormBuilder_TopBar_Upload_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"htmlFormBuilder_topbar_upload"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Upload"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.htmlFormBuilderUpload({e})}/>
                    </span>
                    <div id={"HtmlFormBuilder_TopBar_FileUploader_" + that.dataViewId} style={{display: "none"}}>
                        <FileUploader
                            multiple={false} uploadMode={"useForm"} accept={'.json'}
                            ref={(e) => this.setUIInstance({e, name: "htmlFormBuilderFileUploader_Schema"})}
                            onValueChanged={(e) => this.schemaFileUploaderOnSelectedFileChanged({e})}/>
                    </div>
                </div>
            </React.Fragment>
        )
    };

    customEventOnTrigger(e, dataViewId, parentComp) {
        let that = parentComp;

        let documentModifyType = "leopardsystems.document.modify";
        if (!LDH.IsValueEmpty(parentComp.formDefinition.documentModifyType)) {
            documentModifyType = parentComp.formDefinition.documentModifyType;
        }

        let documentCreateType = "leopardsystems.document.create";
        if (!LDH.IsValueEmpty(parentComp.formDefinition.documentCreateType)) {
            documentCreateType = parentComp.formDefinition.documentCreateType;
        }

        let documentListType = "leopardsystems.document.list";
        if (!LDH.IsValueEmpty(parentComp.formDefinition.documentListType)) {
            documentListType = parentComp.formDefinition.documentListType;
        }

        if (e.component.type === "button") {
            if (e.type === "leopardDataSubmit") {
                let directory = e.component.properties.directory;
                let fileName = e.component.properties.fileName;
                let name = e.component.properties.name;
                if (LDH.IsValueEmpty(name)) name = "FormCompleted";

                let ownerType = "orgid";
                if (typeof that.parentData !== "undefined" && typeof that.parentData.dataFromSource !== "undefined") {
                    let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
                    if (that.parentData.dataFromSource.owner.toLowerCase() === userId.toLowerCase()) {
                        ownerType = "userid";
                    }
                }
                if (LDH.IsValueEmpty(directory) || LDH.IsValueEmpty(fileName) ||
                    LDH.IsValueEmpty(ownerType)) {
                    LRH.ShowToast("Validation failed. " +
                        "The directory, file name or owner type cannot be blank.", "error", 5000);
                    return;
                }
                $("#htmlFormBuilderToobar_" + dataViewId).show();
                let dataToUpload = LDH.DeepClone(that.fullSchemaData);
                dataToUpload.data = LDH.DeepClone(that.formDataToSubmit.data);
                dataToUpload.data["current_page"] = null;
                dataToUpload.data["__dataSubmitted"] = true;
                dataToUpload.data["__dataSubmittedPlatform"] = "control-centre";
                let timestamp = LDH.GetNowLocalString();

                fileName = LDH.ReplaceAll(fileName, "{formName}", name);
                fileName = LDH.ReplaceAll(fileName, "{userName}", window.currentUserName);
                fileName = LDH.ReplaceAll(fileName, "{timestamp}", timestamp);
                let metadata = that.getMetadataForSubmission(that, dataToUpload);

                LeopardAjaxHelper.InsertOrUpdateDocumentToS3(dataToUpload, fileName,
                    directory, function () {
                        LRH.ShowToast("Your file has been successfully uploaded.", "success", 5000);
                        let documentRetrieveType = "leopardsystems.document.retrieve";
                        if (!LDH.IsValueEmpty(that.formDefinition.documentRetrieveType)) {
                            documentRetrieveType = that.formDefinition.documentRetrieveType;
                        }
                        that.retrieveDocumentByParentData(that.parentData, that, documentRetrieveType);
                    }, function () {
                        LRH.ShowToast("Failed to upload the file.", "error", 5000);
                        $("#htmlFormBuilderToobar_" + dataViewId).hide();
                    }, ownerType, documentListType, documentModifyType, documentCreateType, metadata);
            }
        }
    };

    getMetadataForSubmission = (thisComp, dataToUpload) => {
        let that = thisComp;
        let metadata = {};
        if (that.documentMetadata !== null && that.documentMetadata !== {}) {
            metadata = {
                formState: "completed",
                submittedRole: [],
                submittedBranch: ""
            };
        }
        let userProfile = window.userProfile;
        for (let k = 0; k < userProfile.Roles.length; k++) {
            if (userProfile.Roles[k].Type !== "UserRole") {
                continue;
            }
            metadata.submittedRole.push(userProfile.Roles[k].Name);
        }

        for (let i = 0; i < dataToUpload.components.length; i++) {
            if (dataToUpload.components[i].key !== "__formConfig") {
                continue;
            }
            let propKeys = Object.keys(dataToUpload.components[i].properties);
            let props = dataToUpload.components[i].properties;
            for (let j = 0; j < propKeys.length; j++) {
                if (propKeys[j].indexOf("metadata_") === -1) {
                    continue;
                }
                let mValue = props[propKeys[j]];
                let mName = propKeys[j].replace("metadata_", "");
                metadata[mName] = mValue;
            }
        }

        let formKeys = Object.keys(dataToUpload.data["__formConfig"]);
        for (let v = 0; v < formKeys.length; v++) {
            if (formKeys[v].indexOf("metadata_") === -1) {
                continue;
            }
            let config = dataToUpload.data["__formConfig"];
            let metadataValue = config[formKeys[v]];
            let metadataName = formKeys[v].replace("metadata_", "");
            metadata[metadataName] = metadataValue;
        }
        return metadata;
    };

    getFileContentCache = () => {
        return this.fullSchemaData;
    };

    render() {
        let that = this;
        if (that.state.isWidgetInitialized === false) {
            return null;
        }
        let formDefinition = LDH.DeepClone(this.formDefinition);
        let directory = "global";

        let documentRetrieveType = "leopardsystems.document.retrieve";
        if (!LDH.IsValueEmpty(formDefinition.documentRetrieveType)) {
            documentRetrieveType = formDefinition.documentRetrieveType;
        }

        let documentModifyType = "leopardsystems.document.modify";
        if (!LDH.IsValueEmpty(formDefinition.documentModifyType)) {
            documentModifyType = formDefinition.documentModifyType;
        }

        let documentCreateType = "leopardsystems.document.create";
        if (!LDH.IsValueEmpty(formDefinition.documentCreateType)) {
            documentCreateType = formDefinition.documentCreateType;
            directory = "draft";
        }

        let documentListType = "leopardsystems.document.list";
        if (!LDH.IsValueEmpty(formDefinition.documentListType)) {
            documentListType = formDefinition.documentListType;
        }

        let uploadCacheToS3 = false;
        if (!LDH.IsValueEmpty(formDefinition.uploadCacheToS3)) {
            uploadCacheToS3 = formDefinition.uploadCacheToS3;
        }

        return (
            <React.Fragment>
                {this.formToolbar(formDefinition)}
                <LeopardFileUploader
                    dataViewId={that.dataViewId} targetId={"#HtmlFormBuilder_TopBar_Upload_" + that.dataViewId}
                    directory={directory} readOnlyDirectory={false} uploadCacheToS3={uploadCacheToS3}
                    documentRetrieveType={documentRetrieveType} documentModifyType={documentModifyType}
                    documentCreateType={documentCreateType} documentListType={documentListType}
                    getFileContentCache={that.getFileContentCache}
                    fileUploaderList={LeopardDropdownHelper.DropdownSelectionDataGridFormDirectory}
                    addDisposablePopupInstances={(e) => that.addDisposablePopupInstances(e)}
                    setComponentInstance={(e) => that.setComponentInstance(e)}>
                </LeopardFileUploader>
                <div className={"formBuilderViewMode form_viewmode"}
                     style={{display: !that.state.isDesignMode ? "block" : "none"}}>
                    {
                        that.state.isUnmountForms ? null :
                            <LeopardHtmlFormBuilderViewer
                                ref={(e) => this.setUIInstance({e, name: "formViewer"})}
                                updateFormData={this.updateFormData}
                                customEventOnTrigger={this.customEventOnTrigger}
                                dataViewId={that.dataViewId}
                                parentComp={that}
                            />
                    }
                </div>
                <div className={"formBuilderDesignMode form_designmode"}
                     style={{display: that.state.isDesignMode ? "block" : "none"}}>
                    {
                        that.state.isUnmountForms ? null :
                            <LeopardHtmlFormBuilderDesigner
                                ref={(e) => this.setUIInstance({e, name: "formDesigner"})}
                                updateFullSchema={this.updateFullSchema}
                                dataViewId={that.dataViewId}
                                parentComp={that}
                            />
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default LeopardHtmlFormBuilderEngine;
import React from 'react';
import {Column} from 'devextreme-react/data-grid';
import {Button} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import {HeaderFilter, Search} from 'devextreme-react/tree-list';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardCountdownSwitch from './LeopardCountdownSwitch';
import LeopardTooltipWithLink from "./LeopardTooltipWithLink";
import LRH from "../helpers/LeopardReactHelper";
import LPH from "../helpers/LeopardPermissionHelper";
import ScrollView from 'devextreme-react/scroll-view';

export const LeopardGridViewColumnBuilder = (gridDefinition, columnFieldList, thisComp, isGridViewJSONEngine, staticJSONData) => {
    let columnBands = [];

    if (!LDH.IsObjectNull(gridDefinition.customColumnBands) && gridDefinition.customColumnBands.length > 0) {
        let bandsCloned = LDH.DeepClone(gridDefinition.customColumnBands);
        bandsCloned.sort(function (a, b) {
            let orderA = LDH.IsValueEmpty(a.orderIndex) ? 0 : parseInt(a.orderIndex);
            let orderB = LDH.IsValueEmpty(b.orderIndex) ? 0 : parseInt(b.orderIndex);
            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
            return 0;
        });

        for (let v = 0; v < bandsCloned.length; v++) {
            if (LDH.IsObjectNull(bandsCloned[v].sortOrder) || LDH.IsValueEmpty(bandsCloned[v].sortOrder)) {
                bandsCloned[v]["sortOrder"] = 0;
            }
            columnBands.push({
                component: <Column key={"column_band_" + v} caption={bandsCloned[v].name} alignment={"center"}
                                   isBand={true} custombanddata={bandsCloned[v]}></Column>,
                data: bandsCloned[v],
                visibleIndex: bandsCloned[v].orderIndex
            });
        }
    }

    let columnComponent = LeopardGridViewBaseColumnBuilder(gridDefinition, columnFieldList, thisComp,
        isGridViewJSONEngine);

    let columnComponentMerge = [];
    for (let i = 0; i < columnBands.length; i++) {
        columnComponentMerge.push(columnBands[i]);
    }

    if (LDH.IsObjectNull(columnComponent)) {
        columnComponent = [];
    }
    for (let j = 0; j < columnComponent.length; j++) {
        columnComponentMerge.push(columnComponent[j]);
    }

    columnComponentMerge.sort(function (a, b) {
        let orderA = LDH.IsValueEmpty(a.visibleIndex) ? 0 : parseInt(a.visibleIndex);
        let orderB = LDH.IsValueEmpty(b.visibleIndex) ? 0 : parseInt(b.visibleIndex);
        if (orderA < orderB) return -1;
        if (orderA > orderB) return 1;
        return 0;
    });

    let columnComponentResult = [];
    for (let i = 0; i < columnComponentMerge.length; i++) {
        columnComponentResult.push(columnComponentMerge[i].component);
    }

    return columnComponentResult;
};

export const LeopardGridViewBaseColumnBuilder = (gridDefinition, columnFieldList, thisComp, isGridViewJSONEngine) => {
    let columnComponent = [];
    if (columnFieldList === undefined || columnFieldList === null || columnFieldList.length === 0) {
        return null;
    }

    for (let colIndex = 0; colIndex < columnFieldList.length; colIndex++) {
        let columnName = columnFieldList[colIndex];
        let columnDef = null;

        columnDef = gridDefinition.columnDefinition.filter(c => {
            return c.columnName === columnName;
        });
        if (columnDef !== undefined && columnDef !== null && columnDef.length > 0) {
            columnDef = columnDef[0];
        }

        let isCustomColumn = false;
        if (!LDH.IsObjectNull(gridDefinition.customComputedColumns)) {
            let customColumns = gridDefinition.customComputedColumns;
            for (let v = 0; v < customColumns.length; v++) {
                let cName = customColumns[v].name.toLowerCase().trim();
                if (cName === columnName.toLowerCase().trim() &&
                    gridDefinition.gridViewEngine !== "json-engine" &&
                    gridDefinition.gridViewEngine !== "csv-adhoc-engine") {
                    isCustomColumn = true;
                    break;
                }
            }
        }

        let columnType = "string";
        if (columnDef !== null && columnDef.columnType !== undefined &&
            columnDef.columnType !== null && columnDef.columnType !== "") {
            columnType = columnDef.columnType;
        }

        let columnCustomHeader = columnDef.columnName;
        if (columnDef !== null && columnDef.columnCustomHeader !== undefined &&
            columnDef.columnCustomHeader !== null && columnDef.columnCustomHeader !== "") {
            columnCustomHeader = columnDef.columnCustomHeader;
        }

        let allowSorting = !isCustomColumn;
        if (columnDef !== null && columnDef.allowSorting !== undefined && !isCustomColumn &&
            columnDef.allowSorting !== null && columnDef.allowSorting !== "") {
            allowSorting = columnDef.allowSorting;
        }

        let allowFiltering = !isCustomColumn;
        if (columnDef !== null && columnDef.allowFiltering !== undefined && !isCustomColumn &&
            columnDef.allowFiltering !== null && columnDef.allowFiltering !== "") {
            allowFiltering = columnDef.allowFiltering;
        }

        let allowGrouping = !isCustomColumn;
        if (columnDef !== null && columnDef.allowGrouping !== undefined && !isCustomColumn &&
            columnDef.allowGrouping !== null && columnDef.allowGrouping !== "") {
            allowGrouping = columnDef.allowGrouping;
        }

        let allowReordering = true;
        if (columnDef !== null && columnDef.allowReordering !== undefined &&
            columnDef.allowReordering !== null && columnDef.allowReordering !== "") {
            allowReordering = columnDef.allowReordering;
        }

        let allowResizing = true;
        if (columnDef !== null && columnDef.allowResizing !== undefined &&
            columnDef.allowResizing !== null && columnDef.allowResizing !== "") {
            allowResizing = columnDef.allowResizing;
        }

        let isFixed = false;
        if (columnDef !== null && columnDef.isFixed !== undefined &&
            columnDef.isFixed !== null && columnDef.isFixed !== "") {
            isFixed = columnDef.isFixed;
        }

        let columnAlign = "left";
        if (columnDef !== null && columnDef.columnAlign !== undefined &&
            columnDef.columnAlign !== null && columnDef.columnAlign !== "") {
            columnAlign = columnDef.columnAlign;
        }

        let minimumWidth = "50";
        if (columnDef !== null && columnDef.minimumWidth !== undefined &&
            columnDef.minimumWidth !== null && columnDef.minimumWidth !== "") {
            minimumWidth = columnDef.minimumWidth;
        }

        let width = null;
        if (columnDef !== null && columnDef.width !== undefined &&
            columnDef.width !== null && columnDef.width !== "") {
            width = columnDef.width;
        }

        let groupIndex = null;
        if (columnDef !== null && columnDef.groupIndex !== undefined &&
            columnDef.groupIndex !== null && columnDef.groupIndex !== "") {
            groupIndex = columnDef.groupIndex;
        }

        let allowHeaderFilter = !isCustomColumn;
        if (columnDef !== null && columnDef.allowHeaderFilter !== undefined && !isCustomColumn &&
            columnDef.allowHeaderFilter !== null && columnDef.allowHeaderFilter !== "") {
            allowHeaderFilter = columnDef.allowHeaderFilter;
        }

        let sortOrder = null;
        if (columnDef !== null && columnDef.sortOrder !== undefined &&
            columnDef.sortOrder !== null && columnDef.sortOrder !== "") {
            sortOrder = columnDef.sortOrder;
        }

        let isVisible = true;
        if (columnName === "OrderIndex" && gridDefinition.gridViewEngine === "csv-adhoc-engine") {
            sortOrder = "desc";
            isVisible = false;
        }

        let visibleIndex = 0;
        if (columnDef !== null && columnDef.visibleIndex !== undefined &&
            columnDef.visibleIndex !== null && columnDef.visibleIndex !== "") {
            visibleIndex = columnDef.visibleIndex;
        }

        let bandId = null;
        if (columnDef !== null && columnDef.band !== undefined &&
            columnDef.band !== null && columnDef.band !== "") {
            bandId = columnDef.band;
        }

        let filterOperations = [];
        if (LDH.IsValueEmpty(columnType) || columnType === "string" || columnType === "status" || columnType === "color-text") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForString;
        } else if (columnType === "guid") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForString;
        } else if (columnType === "number") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "date") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "datetime") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "boolean") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForBoolean;
        }
        if (columnDef !== null && columnDef.filterOperations !== undefined &&
            columnDef.filterOperations !== null && columnDef.filterOperations !== "") {
            filterOperations = columnDef.filterOperations;
        }

        let colorCoding = "none";
        if (columnDef !== null && columnDef.colorCoding !== undefined &&
            columnDef.colorCoding !== null && columnDef.colorCoding !== "") {
            colorCoding = columnDef.colorCoding;
        }

        let hidingPriority = columnFieldList.length - (colIndex + 1);
        if (gridDefinition.adaptiveColumn === undefined ||
            gridDefinition.adaptiveColumn === null ||
            gridDefinition.adaptiveColumn === false) {
            hidingPriority = null;
        }

        let cssClass = "leopard_gridview_column_template";
        if (allowSorting === true) {
            cssClass += " allow-sorting";
        } else {
            cssClass += " disallow-sorting";
        }

        let domain = LDH.APIEndpointAdapter();
        let url = thisComp.props.GetDataFromUrl.toString().replace("?tableName=", "");

        if (columnType === "date") {
            if (colorCoding === "color-text") {
                columnComponent.push({
                    component: <Column key={"column_" + colIndex} dataField={columnName} dataType={columnType}
                                       format="dd/MM/yyyy" cssClass={cssClass} bandid={bandId}
                                       caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                                       filterOperations={filterOperations} customorderindex={visibleIndex}
                                       cellRender={(e) => thisComp.gridViewColorTextCellRender(e, columnType)}
                                       allowSorting={allowSorting} allowFiltering={allowFiltering}
                                       allowGrouping={allowGrouping} allowReordering={allowReordering}
                                       allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                                       minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                                       visible={isVisible}
                                       sortOrder={sortOrder} hidingPriority={hidingPriority}>
                        {
                            isGridViewJSONEngine ? null :
                                <HeaderFilter dataSource={LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)}>
                                    <Search enabled={true}></Search>
                                </HeaderFilter>
                        }
                    </Column>,
                    visibleIndex: visibleIndex
                });
            } else {
                columnComponent.push({
                    component: <Column key={"column_" + colIndex} dataField={columnName} dataType={columnType}
                                       format="dd/MM/yyyy" cssClass={cssClass} bandid={bandId}
                                       caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                                       filterOperations={filterOperations} customorderindex={visibleIndex}
                                       allowSorting={allowSorting} allowFiltering={allowFiltering}
                                       allowGrouping={allowGrouping} allowReordering={allowReordering}
                                       allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                                       minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                                       visible={isVisible}
                                       sortOrder={sortOrder} hidingPriority={hidingPriority}>
                        {
                            isGridViewJSONEngine ? null :
                                <HeaderFilter dataSource={LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)}>
                                    <Search enabled={true}></Search>
                                </HeaderFilter>
                        }
                    </Column>,
                    visibleIndex: visibleIndex
                });
            }
        } else if (columnType === "datetime") {
            if (colorCoding === "color-text") {
                columnComponent.push({
                    component: <Column key={"column_" + colIndex} dataField={columnName} dataType={columnType}
                                       format="dd/MM/yyyy HH:mm" cssClass={cssClass} bandid={bandId}
                                       caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                                       filterOperations={filterOperations} customorderindex={visibleIndex}
                                       cellRender={(e) => thisComp.gridViewColorTextCellRender(e, columnType)}
                                       allowSorting={allowSorting} allowFiltering={allowFiltering}
                                       allowGrouping={allowGrouping} allowReordering={allowReordering}
                                       allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                                       minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                                       visible={isVisible}
                                       sortOrder={sortOrder} hidingPriority={hidingPriority}>
                        {
                            isGridViewJSONEngine ? null :
                                <HeaderFilter dataSource={LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)}>
                                    <Search enabled={true}></Search>
                                </HeaderFilter>
                        }
                    </Column>,
                    visibleIndex: visibleIndex
                });
            } else {
                columnComponent.push({
                    component: <Column key={"column_" + colIndex} dataField={columnName} dataType={columnType}
                                       format="dd/MM/yyyy HH:mm" cssClass={cssClass} bandid={bandId}
                                       caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                                       filterOperations={filterOperations} customorderindex={visibleIndex}
                                       allowSorting={allowSorting} allowFiltering={allowFiltering}
                                       allowGrouping={allowGrouping} allowReordering={allowReordering}
                                       allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                                       minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                                       visible={isVisible}
                                       sortOrder={sortOrder} hidingPriority={hidingPriority}>
                        {
                            isGridViewJSONEngine ? null :
                                <HeaderFilter dataSource={LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)}>
                                    <Search enabled={true}></Search>
                                </HeaderFilter>
                        }
                    </Column>,
                    visibleIndex: visibleIndex
                });
            }
        } else if (columnType === "status") {
            if (colorCoding === "color-text") {
                columnComponent.push({
                    component: <Column key={"column_" + colIndex} dataField={columnName} dataType={columnType}
                                       cssClass={cssClass} customorderindex={visibleIndex}
                                       caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                                       filterOperations={filterOperations} bandid={bandId}
                                       cellRender={(e) => thisComp.gridViewColorTextCellRender(e, columnType)}
                                       allowSorting={allowSorting} allowFiltering={allowFiltering}
                                       allowGrouping={allowGrouping} allowReordering={allowReordering}
                                       allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                                       minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                                       visible={isVisible}
                                       sortOrder={sortOrder} hidingPriority={hidingPriority}>
                        {
                            isGridViewJSONEngine ? null :
                                <HeaderFilter dataSource={LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)}>
                                    <Search enabled={true}></Search>
                                </HeaderFilter>
                        }
                    </Column>,
                    visibleIndex: visibleIndex
                });
            } else {
                columnComponent.push({
                    component: <Column key={"column_" + colIndex} dataField={columnName} dataType={columnType}
                                       cssClass={cssClass} customorderindex={visibleIndex} bandid={bandId}
                                       caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                                       filterOperations={filterOperations}
                                       cellRender={(e) => thisComp.gridViewStatusCellRender(e)}
                                       allowSorting={allowSorting} allowFiltering={allowFiltering}
                                       allowGrouping={allowGrouping} allowReordering={allowReordering}
                                       allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                                       minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                                       visible={isVisible}
                                       sortOrder={sortOrder} hidingPriority={hidingPriority}>
                        {
                            isGridViewJSONEngine ? null :
                                <HeaderFilter dataSource={LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)}>
                                    <Search enabled={true}></Search>
                                </HeaderFilter>
                        }
                    </Column>,
                    visibleIndex: visibleIndex
                });
            }
        } else {
            if (colorCoding === "color-text") {
                columnComponent.push({
                    component: <Column key={"column_" + colIndex} dataField={columnName} dataType={columnType}
                                       cssClass={cssClass} customorderindex={visibleIndex}
                                       caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                                       filterOperations={filterOperations} bandid={bandId}
                                       cellRender={(e) => thisComp.gridViewColorTextCellRender(e, columnType)}
                                       allowSorting={allowSorting} allowFiltering={allowFiltering}
                                       allowGrouping={allowGrouping} allowReordering={allowReordering}
                                       allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                                       minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                                       visible={isVisible}
                                       sortOrder={sortOrder} hidingPriority={hidingPriority}>
                        {
                            isGridViewJSONEngine ? null :
                                <HeaderFilter dataSource={LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)}>
                                    <Search enabled={true}></Search>
                                </HeaderFilter>
                        }
                    </Column>,
                    visibleIndex: visibleIndex
                });
            } else {
                columnComponent.push({
                    component: <Column key={"column_" + colIndex} dataField={columnName} dataType={columnType}
                                       cssClass={cssClass} customorderindex={visibleIndex}
                                       caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                                       filterOperations={filterOperations} bandid={bandId}
                                       allowSorting={allowSorting} allowFiltering={allowFiltering}
                                       allowGrouping={allowGrouping} allowReordering={allowReordering}
                                       allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                                       minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                                       visible={isVisible}
                                       sortOrder={sortOrder} hidingPriority={hidingPriority}>
                        {
                            isGridViewJSONEngine ? null :
                                <HeaderFilter dataSource={LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)}>
                                    <Search enabled={true}></Search>
                                </HeaderFilter>
                        }
                    </Column>,
                    visibleIndex: visibleIndex
                });
            }
        }
    }
    return columnComponent;
};

export const LeopardGridViewToolbar =
    ({
         addRowButtonOnClick, clearFilterButtonOnClick, viewOptionsButtonOnClick, minHeight,
         gridViewId, exportButtonOnClick, allowEditing, refreshButtonOnClick, applyFilterButtonOnClick,
         showAddButton, explicitFilterOption, gridDefinition, autoRefreshInterval, enableAutoRefresh,
         autoRefreshCountdownOnEnd, showAutoRefreshSwitch, importButtonOnClick, importDataCallback,
         layoutButtonOnClick, downloadButtonOnClick, uploadButtonOnClick, submitDataCallback,
         removeAllButtonOnClick
     }) => {
        let hideAddRowButton = LPH.IsEnableAdminUserOnlyForCommandLink(gridDefinition, "add-row");

        let hideRefreshButton = false;
        if (!LDH.IsObjectNull(gridDefinition.hideRefreshButton) && !LDH.IsValueEmpty(gridDefinition.hideRefreshButton)) {
            hideRefreshButton = gridDefinition.hideRefreshButton;
        }

        let uploadText = "Upload";
        if (!LDH.IsObjectNull(gridDefinition.uploadText) && !LDH.IsValueEmpty(gridDefinition.uploadText)) {
            uploadText = gridDefinition.uploadText;
        }

        return (
            <ScrollView scrollByContent={true} direction="horizontal" height={"auto"}
                        bounceEnabled={false} showScrollbar={'onHover'} scrollByThumb={true}>
                <div className={"leopard-gridview-toolbar"} style={{minHeight}}>
                    {
                        enableAutoRefresh === false ? "" :
                            <span id={"autoRefresh_" + gridViewId} className={"leopard-autorefresh-button_wrapper"}
                                  style={{display: showAutoRefreshSwitch ? "block" : "none"}}>
                         <div id={"autoRefreshCountdownControl_" + gridViewId}>
                            <LeopardCountdownSwitch
                                autoRefreshCountdownOnEnd={autoRefreshCountdownOnEnd}
                                tooltip={"The grid view will be refreshed automatically when timer counts down to 0."}
                                autoRefreshInterval={autoRefreshInterval}
                                fieldValue={enableAutoRefresh} gridViewId={gridViewId}/>
                         </div>
                    </span>
                    }
                    {
                        (!LDH.IsObjectNull(gridDefinition.topMenuJustify) && gridDefinition.topMenuJustify === "right") ?
                            <div style={{width: "100%"}}></div> : <div></div>
                    }
                    {
                        LRH.RenderDataViewParameters(gridDefinition.parameters, gridViewId,
                            gridDefinition, function (data) {
                                if (data.parameterTriggerOnChange === false) {
                                    return;
                                }
                                window[data.controlName] = data.control.value;

                                if (!LDH.IsObjectNull(data.dataShapingForQuery) &&
                                    !LDH.IsValueEmpty(data.dataShapingForQuery)) {
                                    let javascript = data.dataShapingForQuery;
                                    let dataName = "data";
                                    let dataValue = [];
                                    LDH.EvaluateJavaScriptForDataShaping(javascript,
                                        dataName, dataValue, gridViewId);
                                }

                                if (data.autoApplyParameterFilter) {
                                    refreshButtonOnClick({
                                        gridViewId: gridViewId,
                                        definition: gridDefinition,
                                        refreshChildViews: true
                                    });
                                }
                            }, function (data) {
                            }, null, null)
                    }
                    {
                        (LDH.IsObjectNull(gridDefinition.topMenuJustify) || gridDefinition.topMenuJustify === "left") ?
                            <div style={{width: "100%"}}></div> : <div></div>
                    }
                    <span id={"gridViewToobar_" + gridViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{
                            color: "rgb(255, 128, 0)", fontSize: "18px"
                        }}></i>
                        <span className={"toolbar-warming-up-text"}>Warming up backend process...</span>
                    </span>
                    <span className={"button-container"} style={{padding: "0 2px 0 0", float: "right"}}>
                         <LeopardTooltipWithLink
                             elementId={"GridView_TopBar_Layouts_" + gridViewId}
                             additionalClass={"GridView_TopBar_Layout_PId_" + gridDefinition.dataViewPersistentId}
                             labelText={"Layouts"} width={250} title={""} text={""}
                             onClick={(e) => layoutButtonOnClick({e, gridViewId})}/>
                    </span>
                    {
                        (!LDH.IsObjectNull(gridDefinition.removeAllButtonEnabled) && gridDefinition.removeAllButtonEnabled) ?
                            <span className={"button-container"} style={{padding: "0 2px 0 2px", float: "right"}}>
                                <LeopardTooltipWithLink
                                    elementId={"GridView_TopBar_RemoveAll_" + gridViewId}
                                    additionalClass={"GridView_TopBar_RemoveAll_PId_" + gridDefinition.dataViewPersistentId}
                                    labelText={"Remove All"}
                                    width={350} title={"Remove All"}
                                    onClick={(e) => removeAllButtonOnClick({e, gridViewId})}
                                    text={"Remove all data from the grid view."}/>
                            </span>: ""
                    }
                    <span className={"button-container"} style={{padding: "0 2px 0 0", float: "right"}}>
                         <LeopardTooltipWithLink
                             elementId={"GridView_TopBar_ClearFilter_" + gridViewId}
                             additionalClass={"GridView_TopBar_ClearFilter_PId_" + gridDefinition.dataViewPersistentId}
                             labelText={"Reset filter"} width={250} title={"Reset Filter"}
                             onClick={(e) => clearFilterButtonOnClick({e, gridViewId})}
                             text={"The Reset Filter button allows you to reset the filter criteria typed in the gridview's filter boxes."}/>
                    </span>
                    {
                        explicitFilterOption === "auto" ? "" :
                            <span className={"button-container"} style={{padding: "0 2px 0 0", float: "right"}}>
                                <LeopardTooltipWithLink
                                    elementId={"GridView_TopBar_ApplyFilter_" + gridViewId}
                                    additionalClass={"GridView_TopBar_ApplyFilter_PId_" + gridDefinition.dataViewPersistentId}
                                    labelText={"Apply filter"}
                                    width={250} title={"Apply Filter"}
                                    onClick={(e) => applyFilterButtonOnClick({
                                        e,
                                        gridViewId
                                    })}
                                    text={"The Apply Filter button allows you to apply the filter criteria typed in the data view's filter boxes."}/>
                            </span>
                    }
                    <span className={"button-container"} style={{padding: "0 2px 0 0", float: "right"}}>
                        <LeopardTooltipWithLink
                            elementId={"GridView_TopBar_Refresh_" + gridViewId} labelText={"Refresh"}
                            width={250} title={"Refresh"} controlVisible={!hideRefreshButton}
                            additionalClass={"GridView_TopBar_Refresh_PId_" + gridDefinition.dataViewPersistentId}
                            onClick={(e) => refreshButtonOnClick({
                                e: e, gridViewId: gridViewId, definition: gridDefinition
                            })}
                            text={"The Refresh button allows you to refresh data and repaint the data view."}/>
                    </span>
                    {
                        !LDH.IsObjectNull(gridDefinition.urlForDataImportField) && !LDH.IsValueEmpty(gridDefinition.urlForDataImportField) ?
                            <span className={"button-container"}>
                               <span style={{padding: "0 2px 0 2px", float: "right"}}>
                                <LeopardTooltipWithLink
                                    elementId={"GridView_TopBar_Import_" + gridViewId}
                                    additionalClass={"GridView_TopBar_Import_PId_" + gridDefinition.dataViewPersistentId}
                                    labelText={"Import"}
                                    width={350} title={"Import"}
                                    onClick={(e) => importButtonOnClick({e, gridViewId})}
                                    text={"You can import data from a CSV file into the grid view."}/>
                                </span>
                                <input type={"file"} accept={".csv"} id={"GridView_TopBar_ImportWidget_" + gridViewId}
                                       style={{display: "none"}} onChange={
                                    (e) => LDH.ReadTextFile(e, function (data) {
                                        importDataCallback({data: data, definition: gridDefinition});
                                    })}
                                />
                           </span> : ""
                    }
                    {
                        !LDH.IsObjectNull(gridDefinition.showImportProcessButton) && gridDefinition.showImportProcessButton ?
                            <span className={"button-container"}>
                               <span style={{padding: "0 2px 0 2px", float: "right"}}>
                                <LeopardTooltipWithLink
                                    elementId={"GridView_TopBar_Submit_" + gridViewId}
                                    additionalClass={"GridView_TopBar_Submit_PId_" + gridDefinition.dataViewPersistentId}
                                    labelText={gridDefinition.importProcessButtonText}
                                    onClick={(e) => submitDataCallback({e, gridViewId})}/>
                                </span>
                           </span> : ""
                    }
                    {
                        (gridDefinition.enablePagination && gridDefinition.optimizePagerForLargeDataset === false) ||
                        (gridDefinition.gridViewEngine === "json-engine") ||
                        (gridDefinition.gridViewEngine === "csv-adhoc-engine") ?
                            <span className={"button-container"} style={{padding: "0 2px 0 2px", float: "right"}}>
                                <LeopardTooltipWithLink
                                    elementId={"GridView_TopBar_Export_" + gridViewId}
                                    additionalClass={"GridView_TopBar_Export_PId_" + gridDefinition.dataViewPersistentId}
                                    labelText={"Export"} width={350} title={"Export"}
                                    onClick={(e) => exportButtonOnClick({
                                        e: e,
                                        gridViewId: gridViewId,
                                        gridDefinition: gridDefinition
                                    })}
                                    text={""}/>
                            </span> : ""
                    }
                    {
                        (!LDH.IsObjectNull(gridDefinition.downloadOption) && gridDefinition.downloadOption !== "none") ?
                            <span className={"button-container"} style={{padding: "0 2px 0 2px", float: "right"}}>
                                <LeopardTooltipWithLink
                                    elementId={"GridView_TopBar_Download_" + gridViewId}
                                    additionalClass={"GridView_TopBar_Download_PId_" + gridDefinition.dataViewPersistentId}
                                    labelText={"Download"} width={350} title={"Download"}
                                    onClick={(e) => downloadButtonOnClick({
                                        e: e,
                                        gridViewId: gridViewId,
                                        gridDefinition: gridDefinition
                                    })}
                                    text={""}/>
                            </span> : ""
                    }
                    {
                        (!LDH.IsObjectNull(gridDefinition.uploadOption) && gridDefinition.uploadOption !== "none") ?
                            <span className={"button-container"} style={{padding: "0 2px 0 2px", float: "right"}}>
                                <LeopardTooltipWithLink
                                    elementId={"GridView_TopBar_Upload_" + gridViewId}
                                    additionalClass={"GridView_TopBar_Upload_PId_" + gridDefinition.dataViewPersistentId}
                                    labelText={uploadText} width={350} title={uploadText}
                                    onClick={(e) => uploadButtonOnClick({
                                        e: e,
                                        gridViewId: gridViewId,
                                        gridDefinition: gridDefinition
                                    })}
                                    text={""}/>
                            </span> : ""
                    }
                    <span className={"button-container"} style={{padding: "0 2px 0 2px", float: "right"}}>
                        <LeopardTooltipWithLink
                            elementId={"GridView_TopBar_ViewOptions_" + gridViewId}
                            additionalClass={"GridView_TopBar_Columns_PId_" + gridDefinition.dataViewPersistentId}
                            labelText={"Columns"} width={250} title={"Columns"}
                            onClick={(e) => viewOptionsButtonOnClick({
                                e,
                                gridViewId
                            })}
                            text={"This feature allows a user to hide or show columns in grid view at runtime."}/>
                    </span>
                    {
                        allowEditing === false || showAddButton === false || hideAddRowButton ? "" :
                            <span className={"button-container"} style={{padding: "0 2px 0 2px", float: "right"}}>
                                <Button text={'Add Row'} className="leopard-buttonlink"
                                        id={"GridView_TopBar_AddRecord_" + gridViewId}
                                        onClick={(e) => addRowButtonOnClick({e, gridViewId})}/>
                            </span>
                    }
                    <div className={"drag-and-drop-selection-count"}
                         id={gridViewId + "_DragAndDropSelectionCount"}></div>
                </div>
            </ScrollView>
        );
    };

import React from 'react';
import { signIn, signOut, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import $ from 'jquery';
import LDH from '../helpers/LeopardDataHelper';
import LPH from "../helpers/LeopardPermissionHelper";
import LRH from "../helpers/LeopardReactHelper";
import {Amplify} from "aws-amplify";
import LeopardCognitoConfig from "../foundation/LeopardCognitoConfig";

Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

class LeopardSecurity extends React.Component {
    static IsInputValueDangerous = (input) => {
        if (LDH.IsValueEmpty(input)) return false;

        let validity = false;
        if (input.toString().match(/[<>]+/) != null) {
            validity = true;
        }
        return validity;
    };

    static GetCurrentUserRoles = () => {
        return window.userRoles;
    }

    static ShowErrorMessageForLeopardAdminFeature = (component) => {
        let $parent = $(".leopard-leftmenu-item.selected").parent();
        let isDefaultView = $parent.hasClass("dataViewCategory_default-view");
        let errorMsg = "This feature is available for Leopard Admin only. If you want to make some changes, " +
            "please contact Leopard Systems for assistance.";

        if (!LPH.IsLeopardAdminModeEnabled(component) && isDefaultView) {
            LRH.ShowToast(errorMsg, "error", 5000);
            return true;
        }
        return false;
    };

    static async UserLogin(username, password, callbackSuccess, callbackFailed, isTestMode) {
        if (isTestMode === true) {
            let user = {username};
            callbackSuccess("login-success-nochallenge", user);
        } else {
            try {
                await signOut({global: false});
                let user = await signIn({username: username, password: password});
                if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    callbackSuccess("login-success-" + user.challengeName, user);
                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    callbackSuccess("login-success-" + user.challengeName, user);
                } else if (user.challengeName === 'MFA_SETUP') {
                    callbackSuccess("login-success-" + user.challengeName, user);
                } else {
                    callbackSuccess("login-success-nochallenge", user);
                }
            } catch (err) {
                if (err.code === 'UserNotConfirmedException') {
                    callbackFailed("LeopardSecurity_UserLogin_Failed1", err.code);
                } else if (err.code === 'PasswordResetRequiredException') {
                    callbackFailed("LeopardSecurity_UserLogin_Failed2", err.code);
                } else {
                    callbackFailed("LeopardSecurity_UserLogin_Failed3", err);
                }
            }
        }
    }

    static UserLogout() {
        localStorage.setItem("authState", "signIn");
        signOut({global: false});
        window.location.reload();
    }

    static GetCurrentAuthenticatedUser(callbackSuccess, callbackFailed) {
        return getCurrentUser().then(({ username, signInDetails }) => {
            return fetchAuthSession().then(({ tokens: session }) => {
                callbackSuccess({
                    username,
                    session,
                    authenticationFlowType: signInDetails.authFlowType
                });
            }).catch(err => callbackFailed(err));
        }).catch(err => callbackFailed(err));
    };

    static GetCurrentUserCredentials(callbackSuccess, callbackFailed) {
        return fetchAuthSession()
            .then(({ tokens, credentials, identityId, userSub }) => {
                const { accessKeyId, sessionToken, secretAccessKey, expiration } = credentials;
                const authenticated = true;
                callbackSuccess({accessKeyId, sessionToken, authenticated, secretAccessKey, expiration, identityId});
            }).catch(err => callbackFailed(err));
    };

    static GetCurrentUserSession(callbackSuccess, callbackFailed) {
        return fetchAuthSession()
            .then(({ tokens, credentials, identityId, userSub }) => {
                const { idToken, accessToken } = tokens;
                callbackSuccess({idToken, accessToken});
            });
    };
}

export default LeopardSecurity;

import React, {Component} from 'react';
import {connect} from 'react-redux';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import {Button, TextArea, TileView} from "devextreme-react";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import TabPanel from "devextreme-react/tab-panel";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import LeopardAPIGatewayConfig from "./LeopardAPIGatewayConfig";
import $ from "jquery";

class LeopardAttachableWidgetEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customStore: null,
            showLoadingProgress: false,
            disableWidget: false,
            photoData: [],
            messageValue: "",
            messageToUser: ""
        };

        this.uiObjectInstances = {};
        this.disposingAllInstances = false;
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
        this.selectedParentViewData = null;
        this.reportControlInitialized = false;
        this.initializedNotepadListeners = false;
        this.initializedPhotoListeners = false;
        this.initializedReportListeners = false;

        this.testArgs = {
            "Input_DateFrom": "8/1/2023 12:0:0 AM",
            "Input_DateTo": "8/31/2023 12:0:0 AM",
            "Input_TimezoneName": "Australia/Sydney",
            "Input_TimezoneOffset": 11
        };
    }

    setObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.instance) ||
            !LDH.IsObjectNull(this.uiObjectInstances[data.controlName])) {
            return false;
        }
        this.uiObjectInstances[data.controlName] = data.ref.instance;
        return true;
    };

    setNotepadInstance = (dataObj) => {
        let that = this;
        if (this.setObjectInstance(dataObj) === false) {
            return;
        }
        if (this.initializedNotepadListeners) return;
        this.initializedNotepadListeners = true;

        this.props.setNotepadInstance({
            instance: dataObj.ref.instance,
            id: this.props.dataViewId,
            type: "notepad",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstances[dataObj.controlName].option("relationships", linkedList);
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: dataObj.ref.instance,
                callback(data) {
                    let messageToUser = "";
                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            that.selectedParentViewData = data.dataFromSource;
                            if (!LDH.IsObjectNull(data.dataFromSource) &&
                                !LDH.IsObjectNull(data.dataFromSource["Name"]) &&
                                !LDH.IsValueEmpty(data.dataFromSource["Name"])) {
                                messageToUser = data.dataFromSource["Name"];
                            }

                            let firstName = "";
                            let lastName = "";
                            if (!LDH.IsObjectNull(data.dataFromSource) &&
                                !LDH.IsObjectNull(data.dataFromSource["FirstName"]) &&
                                !LDH.IsValueEmpty(data.dataFromSource["FirstName"])) {
                                firstName = data.dataFromSource["FirstName"];
                            }
                            if (!LDH.IsObjectNull(data.dataFromSource) &&
                                !LDH.IsObjectNull(data.dataFromSource["LastName"]) &&
                                !LDH.IsValueEmpty(data.dataFromSource["LastName"])) {
                                lastName = data.dataFromSource["LastName"];
                            }
                            if (!LDH.IsValueEmpty(firstName) && !LDH.IsValueEmpty(lastName)) {
                                messageToUser += " (" + firstName + " " + lastName + ")";
                            }
                        }
                    }
                    that.setState({
                        disableWidget: LDH.IsObjectNull(that.selectedParentViewData),
                        messageToUser: messageToUser
                    });
                }
            });
        } else {
            that.setState({
                disableWidget: LDH.IsObjectNull(that.selectedParentViewData)
            });
        }
    };

    setPhotoInstance = (dataObj) => {
        let that = this;
        if (this.setObjectInstance(dataObj) === false) {
            return;
        }
        if (this.initializedPhotoListeners) return;
        this.initializedPhotoListeners = true;

        this.props.setPhotoInstance({
            instance: dataObj.ref.instance,
            id: this.props.dataViewId,
            type: "photo",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstances[dataObj.controlName].option("relationships", linkedList);
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: dataObj.ref.instance,
                callback(data) {
                    let photoDefinition = that.props.definition.photoDefinition;
                    let id = photoDefinition.parentDataSourceId;
                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let allowErrorMessage = false;
                            that.selectedParentViewData = data.dataFromSource;
                            if (!LDH.IsObjectNull(data.dataFromSource) && !LDH.IsObjectNull(data.parentGridDef) &&
                                !LDH.IsObjectNull(data.parentGridDef.customColumnConfiguration) &&
                                !LDH.IsObjectNull(data.parentGridDef.customColumnConfiguration.customColumns)) {
                                for (let v = 0; v < data.parentGridDef.customColumnConfiguration.customColumns.length; v++) {
                                    let customColumn = data.parentGridDef.customColumnConfiguration.customColumns[v];
                                    if (!LDH.IsObjectNull(customColumn.countColumnName) &&
                                        !LDH.IsValueEmpty(customColumn.countColumnName) &&
                                        customColumn.columnType === "photo-gallery" &&
                                        !LDH.IsValueEmpty(data.dataFromSource[customColumn.countColumnName]) &&
                                        parseInt(data.dataFromSource[customColumn.countColumnName]) > 0) {
                                        allowErrorMessage = true;
                                    }
                                }
                            }
                            if (LDH.IsObjectNull(data.dataFromSource) === false) {
                                that.getDataFromDataSource(data.dataFromSource[id], allowErrorMessage);
                            } else {
                                that.getDataFromDataSource(null, allowErrorMessage);
                            }
                        }
                    }
                }
            });
        }
    };

    showOrHideReportSpinningWheel = (show) => {
        if (show) {
            $(".leopard-attachablewidget-report").addClass("shown");
            $(".leopard-reportwidget-viewer").hide();
        } else {
            $(".leopard-attachablewidget-report").removeClass("shown");
            $(".leopard-reportwidget-viewer").show();
        }
    };

    setReportInstance = (dataObj) => {
        let that = this;
        if (this.setObjectInstance(dataObj) === false) {
            return;
        }
        if (this.initializedReportListeners) return;
        this.initializedReportListeners = true;

        this.props.setReportInstance({
            instance: dataObj.ref.instance,
            id: this.props.dataViewId,
            type: "report",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstances[dataObj.controlName].option("relationships", linkedList);
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: dataObj.ref.instance,
                callback(data) {
                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            that.selectedParentViewData = data.dataFromSource;
                            if (LDH.IsObjectNull(data.dataFromSource) || LDH.IsObjectNull(data.dataFromSource.type) ||
                                LDH.IsObjectNull(data.dataFromSource.name)) {
                                that.uiObjectInstances["reportViewer"] = new window.Stimulsoft.Viewer.StiViewer(
                                    that.defaultReportConfig(), "LeopardReportViewer", false);
                                that.uiObjectInstances["reportViewer"].report = new window.Stimulsoft.Report.StiReport();
                                that.uiObjectInstances["reportViewer"].renderHtml("reportViewerContent");
                                return;
                            }
                            let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);
                            let directory = data.dataFromSource.type;
                            let fileName = data.dataFromSource.name;
                            let documentRetrieveType = "leopardsystems.report.retrieve";
                            that.showOrHideReportSpinningWheel(true);

                            LeopardAjaxHelper.RetrieveDocumentFromS3(loginUserId, fileName, directory, function (documentData) {
                                try {
                                    let payload = (documentData.indexOf("<Code>NoSuchKey</Code>") > -1 || documentData.indexOf("<!DOCTYPE html>") > -1)
                                        ? null : JSON.parse(documentData);

                                    let reportLayout = payload.reportConfig.reportLayout;
                                    let reportMetadata = payload.reportMetadata;
                                    let serviceRequestType = "leopardsystems.report.signurl";

                                    LeopardAjaxHelper.SendEventToReportService(reportMetadata.completedDataSources, function (response) {
                                        let datasources = response.body.data.data;
                                        LRH.GetAthenaDataForReport(datasources, payload.reportConfig, payload.reportArgs, function (ds) {
                                            let report = new window.Stimulsoft.Report.StiReport();
                                            let dataSet = new window.Stimulsoft.System.Data.DataSet("ReportDataSet");
                                            let dataSetTimeZone = new window.Stimulsoft.System.Data.DataSet("ReportTimeZone");
                                            let timezones = LeopardDropdownHelper.DropdownSelectionTimeZone;

                                            let defaultDS = [];
                                            if (!LDH.IsObjectNull(ds) && !LDH.IsObjectNull(ds["_defaultDS"])) {
                                                defaultDS = ds["_defaultDS"];
                                            }
                                            dataSet.readJson({data: defaultDS});
                                            dataSetTimeZone.readJson({timezones: timezones});

                                            report.dictionary.dataStore.clear();
                                            report.regData(dataSet.dataSetName, "", dataSet);
                                            report.regData(dataSetTimeZone.dataSetName, "", dataSetTimeZone);

                                            let customDataSetList = [];
                                            if (!LDH.IsObjectNull(payload.reportConfig.reportCustomDataSources)) {
                                                let sources = payload.reportConfig.reportCustomDataSources;
                                                for (let v = 0; v < sources.length; v++) {
                                                    let dsName = sources[v].name;
                                                    let customDS = new window.Stimulsoft.System.Data.DataSet(dsName);

                                                    let customData = [];
                                                    if (!LDH.IsObjectNull(ds) && !LDH.IsObjectNull(ds[dsName])) {
                                                        customData = ds[dsName];
                                                    }
                                                    let jsonData = {[dsName.toLowerCase()]: customData};
                                                    customDS.readJson(jsonData);
                                                    report.regData(dsName, "", customDS);
                                                    customDataSetList.push({name: dsName, dataset: customDS});
                                                }
                                            }

                                            if (!LDH.IsObjectNull(reportLayout) && !LDH.IsValueEmpty(reportLayout)) {
                                                report.load(reportLayout);

                                                let jsCode = payload.reportConfig.clientSideUILogicForReport;
                                                if (!LDH.IsObjectNull(jsCode) && !LDH.IsValueEmpty(jsCode)) {
                                                    jsCode = LDH.FilterMacro(jsCode);
                                                    LRH.ExecuteClientScript(report, jsCode, "report", false);
                                                }

                                                let variables = report.dictionary.variables;
                                                if (!LDH.IsObjectNull(variables) && variables.list.length > 0) {
                                                    let keys = Object.keys(payload.reportArgs);

                                                    for (let v = 0; v < variables.list.length; v++) {
                                                        let variable = variables.list[v];

                                                        for (let x = 0; x < keys.length; x++) {
                                                            if (variable._name !== keys[x]) {
                                                                continue;
                                                            }
                                                            variable.val = payload.reportArgs[keys[x]];
                                                        }
                                                    }
                                                }

                                                let options = that.defaultReportConfig();
                                                that.uiObjectInstances["reportViewer"] = new window.Stimulsoft.Viewer.StiViewer(
                                                    options, "LeopardReportViewer", false);

                                                that.uiObjectInstances["reportViewer"].report = report;
                                                that.uiObjectInstances["reportViewer"].renderHtml("reportViewerContent");
                                                that.showOrHideReportSpinningWheel(false);
                                            }
                                        });

                                    }, function (error, sessionTimeout) {
                                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                        } else {
                                            LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                                        }
                                    }, serviceRequestType);
                                } catch (ex) {
                                    LRH.ShowToast("The preview is unavailable as the generation process is still underway.", "error", 8000);
                                    that.showOrHideReportSpinningWheel(false);
                                }
                            }, function (error, sessionTimeout) {
                                if (sessionTimeout !== undefined && sessionTimeout === true) {
                                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                } else {
                                    LRH.ShowToast("Failed to retrieve file.", "error", 5000);
                                }
                                that.showOrHideReportSpinningWheel(false);
                            }, documentRetrieveType);
                        }
                    }
                }
            });
        }
    };

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);

        if (!LDH.IsObjectNull(this.props.setPhotoInstance) &&
            typeof this.props.setPhotoInstance === "function") {
            this.props.setPhotoInstance({
                instance: null,
                id: this.props.dataViewId,
                type: "photo",
                isDataView: true
            });
        }

        if (!LDH.IsObjectNull(this.props.setNotepadInstance) &&
            typeof this.props.setNotepadInstance === "function") {
            this.props.setNotepadInstance({
                instance: null,
                id: this.props.dataViewId,
                type: "notepad",
                isDataView: true
            });
        }

        if (!LDH.IsObjectNull(this.props.setReportInstance) &&
            typeof this.props.setReportInstance === "function") {
            this.props.setReportInstance({
                instance: null,
                id: this.props.dataViewId,
                type: "report",
                isDataView: true
            });
        }
    };

    componentDidMount = () => {
        let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
        if ($("#stimulsoft-viewer-css").length === 0) {
            $("head").prepend('<link rel="stylesheet" id="stimulsoft-viewer-css" type="text/css" ' +
                'href="/css/stimulsoft/2022.2.6/stimulsoft.viewer.office2013.whiteorange.css?v=' + version + '" />');
        }
        if ($("#stimulsoft-designer-css").length === 0) {
            $("head").prepend('<link rel="stylesheet" id="stimulsoft-designer-css" type="text/css" ' +
                'href="/css/stimulsoft/2022.2.6/stimulsoft.designer.office2013.whiteorange.css?v=' + version + '" />');
        }
        if ($("#stimulsoft-reports").length === 0) {
            $("head").append('<script id="stimulsoft-reports" async ' +
                'src="/js/stimulsoft/2022.2.6/stimulsoft.reports.js?v=' + version + '"></script>');
        }
        if ($("#stimulsoft-viewer").length === 0) {
            $("head").append('<script id="stimulsoft-viewer" async ' +
                'src="/js/stimulsoft/2022.2.6/stimulsoft.viewer.js?v=' + version + '"></script>');
        }
        if ($("#stimulsoft-designer").length === 0) {
            $("head").append('<script id="stimulsoft-designer" async ' +
                'src="/js/stimulsoft/2022.2.6/stimulsoft.designer.js?v=' + version + '"></script>');
        }
        window.Stimulsoft.Base.StiLicense.key = LeopardStaticUIConfig.ReportLicenseKey;

        let culture = window.Stimulsoft.System.Globalization.CultureInfo.cultures["en-AU"];
        window.Stimulsoft.System.Globalization.CultureInfo.currentCulture = culture;

        this.relationships = this.props.relationships;
    };

    messageValueOnChanged = (e) => {
        this.setState({messageValue: e.value});
    };

    getDataFromDataSource = (itemId, allowErrorMessage) => {
        let that = this;
        if (that.disposingAllInstances === true) {
            return;
        }
        if (LDH.IsObjectNull(itemId) || LDH.IsValueEmpty(itemId)) {
            that.setState({photoData: []});
            return;
        }
        LeopardAjaxHelper.GetAttachmentsByItemId(itemId, allowErrorMessage, function (result) {
            if (that.disposingAllInstances === true) {
                return;
            }
            let items = LDH.GetImageListFromResult(result, itemId);
            that.setState({photoData: items});
        }, function () {
            if (that.disposingAllInstances === false) {
                LRH.ShowToast("Failed to retrieve photos from the server.", "error", 5000);
            }
        }, "", null);
    };

    photoOnClick = (e) => {
        window.open(e.imageData.e.originalImageUrl);
    };

    notepadSubmitButtonOnClick = (e) => {
        let that = this;
        if (LDH.IsObjectNull(that.selectedParentViewData) ||
            LDH.IsObjectNull(that.selectedParentViewData["Name"]) ||
            LDH.IsValueEmpty(that.selectedParentViewData["Name"])) {
            LRH.ShowToast("Please select a recipient from the parent data.", "error", 5000);
            return;
        }
        if (LDH.IsObjectNull(that.state.messageValue) ||
            LDH.IsValueEmpty(that.state.messageValue)) {
            LRH.ShowToast("Your text message cannot be blank.", "error", 5000);
            return;
        }
        that.setState({showLoadingProgress: true}, function () {
            let postTemplate = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
            postTemplate.type = "leopardsystems.chat.send";
            postTemplate.source = LDH.GenerateGuid();

            let postData = {
                "recipients": [{
                    "id": that.selectedParentViewData["Name"],
                    "type": "user"
                }],
                "message": that.state.messageValue
            };

            let notepadDefinition = this.props.definition.notepadDefinition;
            LeopardAjaxHelper.SendRequestByEventSync(function () {
                if (!LDH.IsObjectNull(notepadDefinition.notepadDataSubmissionLogic) &&
                    !LDH.IsValueEmpty(notepadDefinition.notepadDataSubmissionLogic)) {
                    let javascript = notepadDefinition.notepadDataSubmissionLogic;
                    let dataName = "parentRowData";
                    let dataValue = "";
                    LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                        dataValue, that.props.dataViewId, null);
                }
                that.setState({showLoadingProgress: false, messageValue: ""});
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to send a message to the recipient.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            }, postTemplate, postData);
        });
    };

    tileViewItem = (data) => {
        return (
            <React.Fragment>
                <div className={"leopard-thumbnail-image"}
                     style={{width: "100%", height: "100%", padding: "5px"}}>
                    <div className={data.e.id + " leopard-tileviewitem-image"}
                         onClick={(e) => this.photoOnClick({e, imageData: data})}
                         style={{backgroundImage: `url(${data.e.imageUrl})`}}>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    tabItemTitleRender = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        let that = this;

        if (data.e.tabId === 0) {
            return (
                <React.Fragment>
                    <div className={"leopard-photogallery-data-view"}>
                        <TileView dataSource={that.state.photoData} height={"100%"}
                                  baseItemHeight={data.photoDefinition.heightOfThumbnail}
                                  baseItemWidth={data.photoDefinition.widthOfThumbnail}
                                  noDataText={"No photo available to display."}
                                  ref={(e) => that.setPhotoInstance({
                                      ref: e, controlName: "photo_" + that.props.dataViewId
                                  })}
                                  direction={"vertical"} itemMargin={0}
                                  itemComponent={(e) => that.tileViewItem({
                                      e, definition: that.props.definition
                                  })}>
                        </TileView>
                    </div>
                </React.Fragment>
            );
        } else if (data.e.tabId === 1) {
            if (that.reportControlInitialized === false) {
                setTimeout(function () {
                    that.initializeReportControl();
                }, 500);
                that.reportControlInitialized = true;
            }
            return (
                <React.Fragment>
                    <div className={"leopard-attachablewidget-report leopard-loading-icon"}
                         style={{display: "none", margin: "0", width: "100%", height: "500px"}}>
                        <div style={{margin: "auto"}}>
                            <i className="leopard-spinner fas fa-spinner fa-pulse" style={{
                                fontSize: "25px"
                            }}></i>
                        </div>
                    </div>
                    <div className={"leopard-reportwidget-viewer"}
                         style={{display: "flex", height: "100%", width: "100%", overflowY: "auto"}}>
                        <div style={{display: "none"}}>
                            <Button ref={(e) => that.setReportInstance({
                                ref: e, controlName: "report_" + that.props.dataViewId
                            })}>
                            </Button>
                        </div>
                        <div className={"leopard-pdfviewer-container"} style={{width: "100%", padding: "10px"}}>
                            <div id={"reportViewerContent"}></div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    initializeReportControl = () => {
        let that = this;
        let report = new window.Stimulsoft.Report.StiReport();
        let options = that.defaultReportConfig();

        that.uiObjectInstances["reportViewer"] = new window.Stimulsoft.Viewer.StiViewer(
            options, "LeopardReportViewer", false);

        that.uiObjectInstances["reportViewer"].report = report;
        that.uiObjectInstances["reportViewer"].renderHtml("reportViewerContent");
    };

    defaultReportConfig = () => {
        let options = new window.Stimulsoft.Viewer.StiViewerOptions();
        options.appearance.interfaceType = window.Stimulsoft.Viewer.StiInterfaceType.Mouse;
        options.appearance.showTooltipsHelp = false;
        options.appearance.scrollbarsMode = false;
        options.toolbar.showDesignButton = false;
        options.toolbar.showAboutButton = false;
        options.toolbar.showOpenButton = false;
        options.toolbar.showParametersButton = false;

        options.exports.showExportToText = false;
        options.exports.showExportToPowerPoint = false;
        options.exports.showExportToOpenDocumentCalc = false;
        options.exports.showExportToOpenDocumentWriter = false;
        options.exports.showExportToJson = false;
        options.exports.showExportToCsv = false;
        options.exports.showExportToWord2007 = false;
        options.exports.showExportToDocument = false;
        return options;
    };

    render() {
        if (this.disposingAllInstances) return null;

        if (this.props.dashboardItemType === "notepad") {
            let notepadDefinition = this.props.definition.notepadDefinition;
            return (
                <React.Fragment>
                    <div style={{padding: "10px", height: "100%"}}>
                        <TextArea disabled={this.state.showLoadingProgress || this.state.disableWidget}
                                  height={"calc(100% - 45px)"} value={this.state.messageValue}
                                  onValueChanged={(e) => this.messageValueOnChanged(e)}
                                  ref={(e) => this.setNotepadInstance({
                                      ref: e, controlName: "notepad_messagebox_" + this.props.dataViewId
                                  })} maxLength={1000}
                                  placeholder={"Enter your message here..."}>
                        </TextArea>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div className={"leopard-messagewidget-messageto-text"}
                                 style={{visibility: LDH.IsValueEmpty(this.state.messageToUser) ? "hidden" : "visible"}}>
                                <span style={{fontWeight: "bold", marginRight: "5px"}}>Message To:</span>
                                <span>{this.state.messageToUser}</span>
                            </div>
                            <div style={{marginTop: "10px", display: "flex"}}>
                            <span className={"leopard-loading-icon notepad_" + this.props.dataViewId} style={{
                                marginRight: "15px",
                                marginLeft: "30px",
                                display: this.state.showLoadingProgress ? "block" : "none"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                                <Button className="leopard-button auto-width with-padding"
                                        text={notepadDefinition.notepadButtonText}
                                        disabled={this.state.showLoadingProgress || this.state.disableWidget}
                                        ref={(e) => this.setObjectInstance({
                                            ref: e, controlName: "notepad_submitbutton_" + this.props.dataViewId
                                        })} onClick={(e) => this.notepadSubmitButtonOnClick(e)}>
                                </Button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            let photoDefinition = this.props.definition.photoDefinition;
            let panelSource = LDH.DeepClone(LeopardDropdownHelper.DropdownSelectionAttachmentTabPanel);
            if (!LDH.IsObjectNull(photoDefinition.photoGalleryDisabledTabs)) {
                for (let i = 0; i < photoDefinition.photoGalleryDisabledTabs.length; i++) {
                    if (photoDefinition.photoGalleryDisabledTabs[i] === "photo") {
                        panelSource[0].disabled = true;
                    } else if (photoDefinition.photoGalleryDisabledTabs[i] === "report") {
                        panelSource[1].disabled = true;
                    }
                }
            }
            let selectedIndex = 0;
            if (!LDH.IsObjectNull(photoDefinition.photoGalleryDefaultTab) &&
                photoDefinition.photoGalleryDefaultTab === "report") {
                selectedIndex = 1;
            }

            return (
                <React.Fragment>
                    <div className={"leopard-attachment-widget-container"}>
                        <TabPanel dataSource={panelSource} animationEnabled={true} swipeEnabled={false}
                                  showNavButtons={true} selectedIndex={selectedIndex}
                                  itemTitleRender={this.tabItemTitleRender}
                                  itemComponent={(e) => this.tabItemContent({
                                      e, photoDefinition
                                  })}
                                  ref={(e) => this.setObjectInstance({
                                      ref: e, controlName: "attachment_tabpanel_" + this.props.dataViewId
                                  })}>
                        </TabPanel>
                    </div>
                </React.Fragment>
            );
        }
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardAttachableWidgetEngine);
